import { useMutation, useQuery } from 'urql';
import React from 'react';
import { useLocation } from 'wouter';
import {
  SignUpFormElementsQuery,
  UpdateSignUpFormElementsMutation,
} from './SignUpFormPage.tsx';
import { SignUpFormElementForm } from './SignUpFormElementForm.tsx';
import { SignUpCustomFieldInput } from '../../gql/graphql.ts';
import { cloneAndRemoveTypename } from '../../public/util.ts';

interface SignUpFormElementEditProps {
  companyId: string;
  signUpFormElementId: string;
}

export const SignUpFormElementEditPage: React.FC<
  SignUpFormElementEditProps
> = ({ companyId, signUpFormElementId }) => {
  const [{ data }] = useQuery({
    query: SignUpFormElementsQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });
  const [, updateSignUpForm] = useMutation(UpdateSignUpFormElementsMutation);
  const [, setLocation] = useLocation();

  const onFormSubmit = (value: SignUpCustomFieldInput) => {
    if (data?.companySignUpCustomFields) {
      updateSignUpForm({
        companyId,
        signUpFormElements: cloneAndRemoveTypename(
          data.companySignUpCustomFields.map((ele) =>
            ele.id === signUpFormElementId ? value : ele,
          ),
        ),
      }).then(() => {
        setLocation('/sign-up-form-elements');
      });
    }
  };

  return (
    <SignUpFormElementForm
      value={
        data?.companySignUpCustomFields &&
        cloneAndRemoveTypename(
          data?.companySignUpCustomFields.find(
            (ele) => ele.id === signUpFormElementId,
          ),
        )
      }
      onSubmit={(s) => onFormSubmit(s)}
      allFields={
        data ? cloneAndRemoveTypename(data.companySignUpCustomFields) : []
      }
    />
  );
};
