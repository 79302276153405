import { useMutation, useQuery } from 'urql';
import { graphql } from '../../gql';
import React from 'react';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { Link } from 'wouter';
import { HeroIcon } from '../../shared/HeroIcon.tsx';
import { PageTitle } from '../PageTitle.tsx';

interface CompanySitesProps {
  companyId: string;
}

const CompanySitesQuery = graphql(/* GraphQL */ `
  query companySites($companyId: String!) {
    companySites(companyId: $companyId) {
      title
      slug
      draft
      externalUrl
    }
  }
`);

const DeleteComanySiteMutation = graphql(/* GraphQL */ `
  mutation deleteCompanySite($companyId: String!, $slug: String!) {
    deleteCompanySite(companyId: $companyId, slug: $slug)
  }
`);

export const CompanySitesPage: React.FC<CompanySitesProps> = ({
  companyId,
}) => {
  // TODO: Error handling
  const [{ data }, reExecuteCompanySitesQuery] = useQuery({
    query: CompanySitesQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });

  const [, deleteCompanySite] = useMutation(DeleteComanySiteMutation);

  const deleteSite = (slug: string) => {
    deleteCompanySite({ companyId, slug }).then(() =>
      reExecuteCompanySitesQuery(),
    );
  };

  return (
    <>
      <PageTitle title="Seiten" />

      <div className={'mb-4 flex justify-end'}>
        <Link
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          href={'/sites/new'}
        >
          <HeroIcon name="DocumentPlus" className="block h-5 w-5" />
          Neue Seite
        </Link>
      </div>

      <Grid
        className="h-auto [&_.k-grid-norecords-template]:border-0"
        data={data?.companySites}
        pageable
      >
        <GridNoRecords>Keine Seiten gefunden</GridNoRecords>
        <GridColumn field="title" title="Titel" />
        <GridColumn field="slug" title="Navigation Titel" />
        <GridColumn field="draft" title="Entwurf" />
        <GridColumn field="externalUrl" title="URL" />
        <GridColumn
          width={'120px'}
          cell={(props) => (
            <td className={'k-button-link-secondary flex space-x-2'}>
              <Link
                href={`/sites/edit/${props.dataItem.slug}`}
                className="k-button k-button-md k-button-outline k-button-outline-secondary k-rounded-md !text-[#6c757d] hover:!text-white"
                title="Seite bearbeiten"
              >
                <HeroIcon name="Pencil" className="block h-5 w-5" />
              </Link>
              <button
                className="k-button k-button-md k-button-outline k-button-outline-secondary k-rounded-md"
                onClick={() => deleteSite(props.dataItem.slug)}
                title="Seite löschen"
              >
                <HeroIcon name="Trash" className="block h-5 w-5" />
              </button>
            </td>
          )}
        />
      </Grid>
    </>
  );
};
