import { createContext, useState } from 'react';
import { GetPublicCompanyQuery } from '../../gql/graphql.ts';
import { PublicCompanyQuery } from '../queries/PublicQueries';
import { useQuery } from 'urql';
import { useDeepCompareEffect } from 'react-use';
import { cloneAndRemoveTypename } from '../util.ts';

export type PublicCompanyData = GetPublicCompanyQuery['publicCompany'];

export type PublicCompanyContextType = [
  company: PublicCompanyData | undefined,
  setCompany: React.Dispatch<React.SetStateAction<PublicCompanyData>>,
];

export const PublicCompanyContext = createContext<PublicCompanyContextType>([
  undefined,
  () => {},
]);

interface PublicCompanyProviderProps {
  children: React.ReactNode;
}

export const PublicCompanyProvider: React.FC<PublicCompanyProviderProps> = ({
  children,
}) => {
  const [company, setCompany] = useState<PublicCompanyData>();

  const [publicCompanyQueryResult] = useQuery({
    query: PublicCompanyQuery,
  });

  const { publicCompany } = publicCompanyQueryResult.data || {};

  useDeepCompareEffect(() => {
    if (publicCompany) {
      setCompany(cloneAndRemoveTypename(publicCompany));
    }
  }, [publicCompanyQueryResult.data]);

  return (
    <PublicCompanyContext.Provider
      value={[
        company,
        setCompany as React.Dispatch<React.SetStateAction<PublicCompanyData>>,
      ]}
    >
      {children}
    </PublicCompanyContext.Provider>
  );
};
