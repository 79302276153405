import { useMutation, useQuery } from 'urql';
import React from 'react';
import { useLocation } from 'wouter';
import {
  SignUpFormElementsQuery,
  UpdateSignUpFormElementsMutation,
} from './SignUpFormPage.tsx';
import { SignUpFormElementForm } from './SignUpFormElementForm.tsx';
import { SignUpCustomFieldInput } from '../../gql/graphql.ts';
import { cloneAndRemoveTypename } from '../../public/util.ts';

interface SignUpFormElementNewProps {
  companyId: string;
}

export const SignUpFormElementNewPage: React.FC<SignUpFormElementNewProps> = ({
  companyId,
}) => {
  const [{ data }] = useQuery({
    query: SignUpFormElementsQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });
  const [, updateSignUpForm] = useMutation(UpdateSignUpFormElementsMutation);
  const [, setLocation] = useLocation();

  const onFormSubmit = (value: SignUpCustomFieldInput) => {
    if (data?.companySignUpCustomFields) {
      updateSignUpForm({
        companyId,
        signUpFormElements: cloneAndRemoveTypename([
          ...data.companySignUpCustomFields,
          value,
        ]),
      }).then(() => {
        setLocation('/sign-up-form-elements');
      });
    }
  };

  return (
    <SignUpFormElementForm
      value={{
        required: false,
        systemField: false,
        options: [],
        name: '',
        showAfter: false,
        showInMail: false,
      }}
      onSubmit={(s) => onFormSubmit(s)}
      allFields={
        data ? cloneAndRemoveTypename(data.companySignUpCustomFields) : []
      }
    />
  );
};
