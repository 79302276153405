import { PublicSeminar } from '../gql/graphql.ts';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import { LecturerCard } from './LecturerCard.tsx';
import { getFormattedDate, getFormattedSeminarDate } from './util.ts';

interface SidebarItemProps {
  title: string;
  icon: string;
  children?: React.ReactNode;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ title, icon, children }) => {
  return (
    <div className="flex flex-col gap-2">
      <h3 className="flex items-center gap-1 border-b pb-1">
        <HeroIcon
          name={icon}
          className="mr-1 mt-1 h-6 w-6 shrink-0 text-bts-theme-primary"
        />
        {title}
      </h3>
      <div className="flex gap-2">{children}</div>
    </div>
  );
};

interface PublicDetailsSidebarProps {
  seminar: Pick<
    PublicSeminar,
    | 'dateText'
    | 'startDate'
    | 'endDate'
    | 'locationText'
    | 'registrationDate'
    | 'lecturers'
    | 'price'
    | 'priceText'
    | 'price2'
    | 'priceText2'
    | 'price3'
    | 'priceText3'
    | 'price4'
    | 'priceText4'
    | 'isAlmostBookedOut'
    | 'isBookedOut'
  >;
  showSignalLight?: boolean;
}

export const PublicDetailsSidebar: React.FC<PublicDetailsSidebarProps> = ({
  seminar,
  showSignalLight,
}) => {
  // If there is ONLY a priceText, we show the priceText
  // If there is ONLY a numerical value, we show the numerical value (format it correctly and suffix it with "Euro")
  // If there is a priceText AND a numerical value, we show both (format and suffix the numerical value)
  const prices = [
    { value: seminar?.price, text: seminar?.priceText },
    { value: seminar?.price2, text: seminar?.priceText2 },
    { value: seminar?.price3, text: seminar?.priceText3 },
    { value: seminar?.price4, text: seminar?.priceText4 },
  ];

  const pricesLength = prices
    .map((p) => Boolean(p.value || p.text))
    .filter(Boolean).length;

  return (
    <aside className="col-span-1 flex flex-col gap-6 md:col-span-3">
      <SidebarLecturersItem lecturers={seminar.lecturers} />
      {(seminar?.dateText || seminar?.startDate || seminar?.endDate) && (
        <SidebarItem icon="Calendar" title="Termin">
          {getFormattedSeminarDate(
            seminar.startDate,
            seminar.endDate,
            seminar.dateText,
          )}
        </SidebarItem>
      )}
      {seminar?.locationText && (
        <SidebarItem icon="MapPin" title="Ort">
          {seminar?.locationText}
        </SidebarItem>
      )}

      {pricesLength > 0 && (
        <SidebarItem
          icon="CurrencyEuro"
          title={`Preis${pricesLength > 1 ? 'e' : ''}`}
        >
          <dl className="flex flex-col gap-2">
            {prices?.map(
              (price, i) =>
                (Boolean(price.value) || Boolean(price?.text?.trim())) && (
                  <div key={'price' + (i + 1)}>
                    {price.value ? (
                      <>
                        <dt className="text-lg font-semibold">{`${price.value.toLocaleString('de-DE', { minimumFractionDigits: 2 })} Euro`}</dt>
                        {Boolean(price?.text?.trim()) && (
                          <dd className="text-sm">{`(${price.text?.trim()})`}</dd>
                        )}
                      </>
                    ) : (
                      <dt className="text-lg font-semibold">{`${price.text?.trim()}`}</dt>
                    )}
                  </div>
                ),
            )}
          </dl>
        </SidebarItem>
      )}
      {(showSignalLight || seminar.registrationDate) && (
        <SidebarItem icon="Bell" title="Anmeldestatus">
          <div className="flex flex-col gap-6">
            {seminar.registrationDate && (
              <div>
                Anmeldeschluss ist der
                <br />
                {getFormattedDate(seminar.registrationDate)}
              </div>
            )}

            {showSignalLight && seminar.isBookedOut && (
              <div className="flex gap-2">
                <div className="h-6 w-6 shrink-0 rounded-full bg-red-500"></div>{' '}
                Veranstaltung ist ausgebucht
              </div>
            )}
            {showSignalLight && seminar.isAlmostBookedOut && (
              <div className="flex gap-2">
                <div className="h-6 w-6 shrink-0 rounded-full bg-orange-500"></div>{' '}
                Veranstaltung ist fast ausgebucht
              </div>
            )}
            {showSignalLight &&
              !seminar.isAlmostBookedOut &&
              !seminar.isBookedOut && (
                <div className="flex gap-2">
                  <div className="h-6 w-6 shrink-0 rounded-full bg-green-500"></div>{' '}
                  Plätze verfügbar
                </div>
              )}
          </div>
        </SidebarItem>
      )}
    </aside>
  );
};

interface SidebarLecturersItemProps {
  lecturers: PublicSeminar['lecturers'];
}

const SidebarLecturersItem: React.FC<SidebarLecturersItemProps> = ({
  lecturers,
}) => {
  return (
    lecturers &&
    lecturers?.length > 0 && (
      <SidebarItem
        icon="UserGroup"
        title={
          Array.isArray(lecturers) && lecturers.length > 1
            ? 'Referenten'
            : 'Referent'
        }
      >
        <ul className="flex w-full list-inside list-disc flex-col items-center gap-2">
          {lecturers?.map((lecturer, i) => (
            <LecturerCard
              key={'lecturer' + i}
              image={lecturer?.pathToProfileImage as string}
              title={lecturer?.name as string}
              subtitle={lecturer?.jobTitle as string}
              vita={lecturer?.vita as string}
            />
          ))}
        </ul>
      </SidebarItem>
    )
  );
};
