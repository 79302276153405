import { useMutation, useQuery } from 'urql';
import React from 'react';
import { graphql } from '../../gql/index.ts';
import { UserDto, UserForm } from './UserForm.tsx';
import { useLocation } from 'wouter';
import { cloneAndRemoveTypename } from '../../public/util.ts';

interface UserEditPageProps {
  companyId: string;
  userId: string;
}

const UserQuery = graphql(/* GraphQL */ `
  query user($companyId: String!, $userId: String!) {
    user(companyId: $companyId, userId: $userId) {
      title
      firstName
      lastName
      email
      privileges
    }
  }
`);

const UpdateUserMutation = graphql(/* GraphQL */ `
  mutation updateUser(
    $companyId: String!
    $userId: String!
    $updatedValues: UpdateUserDto!
  ) {
    updateUser(
      companyId: $companyId
      userId: $userId
      updatedValues: $updatedValues
    )
  }
`);

export const UserEditPage: React.FC<UserEditPageProps> = ({
  companyId,
  userId,
}) => {
  // TODO: Error handling
  const [{ data }] = useQuery({
    query: UserQuery,
    variables: { companyId, userId },
    pause: companyId === undefined || companyId === null || !userId,
  });

  const [, updateUser] = useMutation(UpdateUserMutation);
  const [, setLocation] = useLocation();

  const onFormSubmit = (value: UserDto) => {
    updateUser({
      companyId,
      userId,
      updatedValues: value,
    }).then(() => {
      setLocation('/users');
    });
  };

  return (
    <UserForm
      value={data?.user && cloneAndRemoveTypename(data?.user)}
      onSubmit={onFormSubmit}
    />
  );
};
