import { useState } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { LecturerProfileImage } from './LecturerProfileImage';

interface LecturerCardProps {
  title: string;
  subtitle?: string;
  image?: string;
  vita?: string;
}

export const LecturerCard: React.FC<LecturerCardProps> = ({
  title,
  subtitle,
  image,
  vita,
}) => {
  const [showLecturerModal, setShowLecturerModal] = useState(false);
  return (
    <>
      {showLecturerModal && vita && (
        <Dialog
          autoFocus={true}
          width={650}
          title={'Dozentenprofil'}
          onClose={() => {
            setShowLecturerModal(false);
          }}
        >
          <div className="flex gap-4">
            <LecturerProfileImage src={image} size="large" />
            <div>
              <h2 className={`${subtitle ? '' : 'mb-4'}`}>{title}</h2>
              {subtitle && (
                <h5 className="mb-4 text-sm text-black/80">{subtitle}</h5>
              )}
              <p
                className="prose prose-headings:font-medium prose-h1:text-3xl prose-h2:text-2xl prose-h3:text-xl prose-h4:text-lg"
                dangerouslySetInnerHTML={{ __html: vita }}
              />
            </div>
          </div>
        </Dialog>
      )}
      <li className={`flex w-full items-center gap-4`}>
        {title && (
          <LecturerProfileImage
            src={image}
            {...(vita
              ? {
                  onClick: () => {
                    setShowLecturerModal(true);
                  },
                }
              : {})}
          />
        )}
        <div
          className={`flex flex-col ${vita ? 'cursor-pointer' : ''}`}
          {...(vita
            ? {
                onClick: () => {
                  setShowLecturerModal(true);
                },
              }
            : {})}
        >
          {title && <div className="font-semibold">{title}</div>}
          {title && subtitle && <div>{subtitle}</div>}
        </div>
      </li>
    </>
  );
};
