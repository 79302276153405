/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query companyBanner($companyId: String!) {\n    company(companyId: $companyId) {\n      visualSettings {\n        pathToBanner\n      }\n    }\n  }\n": types.CompanyBannerDocument,
    "\n  mutation removeBanner($companyId: String!) {\n    removeBanner(companyId: $companyId) {\n      id\n    }\n  }\n": types.RemoveBannerDocument,
    "\n  query companyCategories($companyId: String!) {\n    companyCategories(companyId: $companyId) {\n      id\n      name\n      children {\n        id\n        name\n        children {\n          id\n          name\n          children {\n            id\n            name\n            children {\n              id\n              name\n              children {\n                id\n                name\n                children {\n                  id\n                  name\n                  children {\n                    id\n                    name\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n": types.CompanyCategoriesDocument,
    "\n  mutation updateCategories(\n    $companyId: String!\n    $categories: [CompanyCategoryInput!]!\n  ) {\n    updateCategories(companyId: $companyId, categories: $categories)\n  }\n": types.UpdateCategoriesDocument,
    "\n  query companyFlags($companyId: String!) {\n    companyFlags(companyId: $companyId) {\n      flag1\n      flag2\n      flag3\n      flag4\n      flag5\n      flag6\n      flag7\n      flag8\n      flag9\n      flag10\n      flag11\n      flag12\n      flag13\n      flag14\n      flag15\n      flag16\n      flag17\n      flag18\n      flag19\n      flag20\n    }\n  }\n": types.CompanyFlagsDocument,
    "\n  mutation updateCompanyFlags($companyId: String!, $flags: FlagsInput!) {\n    updateCompanyFlags(companyId: $companyId, flags: $flags) {\n      flag1\n      flag2\n      flag3\n      flag4\n      flag5\n      flag6\n      flag7\n      flag8\n      flag9\n      flag10\n      flag11\n      flag12\n      flag13\n      flag14\n      flag15\n      flag16\n      flag17\n      flag18\n      flag19\n      flag20\n    }\n  }\n": types.UpdateCompanyFlagsDocument,
    "\n  query companySettings($companyId: String!) {\n    company(companyId: $companyId) {\n      visualSettings {\n        showTutor\n        showEndOfRegistrationForSeminar\n        showDescription\n        showSeminarNumber\n        showPrice\n        showDate\n        showLocation\n        showSignalLight\n        backToDashboardActive\n        backToMainActive\n        primaryColor\n        primaryTextColor\n      }\n      secret\n      active\n      domain\n      functionalSettings {\n        errorTextWrongPassword\n        infoText\n        passwordActive\n        priceCalculation\n        registrationActive\n        searchActive\n        seminarsOrderBy\n        showPastSeminars\n        waitlistActive\n        disableUserAccounts\n        showBookedOutSeminars\n      }\n    }\n  }\n": types.CompanySettingsDocument,
    "\n  mutation updateCompanySettings(\n    $companyId: String!\n    $company: UpdateCompanyDto!\n  ) {\n    updateCompany(companyId: $companyId, company: $company) {\n      visualSettings {\n        showTutor\n        showEndOfRegistrationForSeminar\n        showDescription\n        showSeminarNumber\n        showPrice\n        showDate\n        showLocation\n        showSignalLight\n        backToDashboardActive\n        backToMainActive\n        primaryColor\n        primaryTextColor\n      }\n      active\n      domain\n      functionalSettings {\n        errorTextWrongPassword\n        infoText\n        passwordActive\n        priceCalculation\n        registrationActive\n        searchActive\n        seminarsOrderBy\n        showPastSeminars\n        waitlistActive\n        disableUserAccounts\n        showBookedOutSeminars\n      }\n    }\n  }\n": types.UpdateCompanySettingsDocument,
    "\n  query company($companyId: String!) {\n    company(companyId: $companyId) {\n      mailSettings {\n        smtpHost\n        smtpPort\n        smtpUser\n        smtpPassword\n        smtpTls\n        senderName\n        senderMail\n        allMailsSendToBcc\n        sendConfirmationMail\n        sendConfirmationMailWaitlist\n        sendReminderSevenDays\n        sendReminderOneDay\n      }\n    }\n  }\n": types.CompanyDocument,
    "\n  mutation sendTestMail($companyId: String!, $receiver: String!) {\n    sendTestMail(companyId: $companyId, receiver: $receiver)\n  }\n": types.SendTestMailDocument,
    "\n  mutation updateCompany($companyId: String!, $company: UpdateCompanyDto!) {\n    updateCompany(companyId: $companyId, company: $company) {\n      id\n      mailSettings {\n        smtpHost\n        smtpPort\n        smtpUser\n        smtpPassword\n        smtpTls\n        senderName\n        senderMail\n        allMailsSendToBcc\n        sendConfirmationMail\n        sendConfirmationMailWaitlist\n        sendReminderSevenDays\n        sendReminderOneDay\n      }\n    }\n  }\n": types.UpdateCompanyDocument,
    "\n  query companyPublicSeminarPageFields($companyId: String!) {\n    companyPublicSeminarPageFields(companyId: $companyId) {\n      fieldName\n      type\n      markdown\n    }\n  }\n": types.CompanyPublicSeminarPageFieldsDocument,
    "\n  mutation updatePublicSeminarPageFields(\n    $publicSeminarPageFields: [PublicSeminarPageFieldInput!]!\n    $companyId: String!\n  ) {\n    updatePublicSeminarPageFields(\n      companyId: $companyId\n      publicSeminarPageFields: $publicSeminarPageFields\n    )\n  }\n": types.UpdatePublicSeminarPageFieldsDocument,
    "\n  query getOneSite($companyId: String!, $slug: String!) {\n    companySite(companyId: $companyId, slug: $slug) {\n      title\n      body\n      slug\n      isFooterSite\n      draft\n      externalUrl\n    }\n  }\n": types.GetOneSiteDocument,
    "\n  mutation updateCompanySite(\n    $companyId: String!\n    $slug: String!\n    $site: SiteInput!\n  ) {\n    updateCompanySite(companyId: $companyId, slug: $slug, site: $site) {\n      slug\n      title\n      body\n      draft\n      externalUrl\n      isFooterSite\n    }\n  }\n": types.UpdateCompanySiteDocument,
    "\n  mutation addCompanySite($companyId: String!, $site: SiteInput!) {\n    addCompanySite(companyId: $companyId, site: $site) {\n      slug\n      title\n      body\n      draft\n      externalUrl\n      isFooterSite\n    }\n  }\n": types.AddCompanySiteDocument,
    "\n  query companySites($companyId: String!) {\n    companySites(companyId: $companyId) {\n      title\n      slug\n      draft\n      externalUrl\n    }\n  }\n": types.CompanySitesDocument,
    "\n  mutation deleteCompanySite($companyId: String!, $slug: String!) {\n    deleteCompanySite(companyId: $companyId, slug: $slug)\n  }\n": types.DeleteCompanySiteDocument,
    "\n  mutation previewMjml($companyId: String!, $mjmlTemplate: String!) {\n    previewMjml(companyId: $companyId, mjmlTemplate: $mjmlTemplate) {\n      body\n    }\n  }\n": types.PreviewMjmlDocument,
    "\n  query mailOneDayTemplate($companyId: String!) {\n    company(companyId: $companyId) {\n      mailSettings {\n        mailTemplateReminderOneDay {\n          subject\n          template\n        }\n      }\n    }\n  }\n": types.MailOneDayTemplateDocument,
    "\n  mutation updateTemplateOneDay(\n    $companyId: String!\n    $company: UpdateCompanyDto!\n  ) {\n    updateCompany(companyId: $companyId, company: $company) {\n      mailSettings {\n        mailTemplateReminderOneDay {\n          subject\n          template\n        }\n      }\n    }\n  }\n": types.UpdateTemplateOneDayDocument,
    "\n  query mailSevenDays($companyId: String!) {\n    company(companyId: $companyId) {\n      mailSettings {\n        mailTemplateReminderSevenDays {\n          subject\n          template\n        }\n      }\n    }\n  }\n": types.MailSevenDaysDocument,
    "\n  mutation updateTemplateSevenDays(\n    $companyId: String!\n    $company: UpdateCompanyDto!\n  ) {\n    updateCompany(companyId: $companyId, company: $company) {\n      mailSettings {\n        mailTemplateReminderSevenDays {\n          subject\n          template\n        }\n      }\n    }\n  }\n": types.UpdateTemplateSevenDaysDocument,
    "\n  query mailSettingsParticipantTemplateQuery($companyId: String!) {\n    company(companyId: $companyId) {\n      mailSettings {\n        templateParticipantMail {\n          subject\n          template\n        }\n      }\n    }\n  }\n": types.MailSettingsParticipantTemplateQueryDocument,
    "\n  mutation updateParticipantTemplateQuery(\n    $companyId: String!\n    $company: UpdateCompanyDto!\n  ) {\n    updateCompany(companyId: $companyId, company: $company) {\n      mailSettings {\n        templateParticipantMail {\n          subject\n          template\n        }\n      }\n    }\n  }\n": types.UpdateParticipantTemplateQueryDocument,
    "\n  query mailSettingsParticipantWaitlistTemplateQuery($companyId: String!) {\n    company(companyId: $companyId) {\n      mailSettings {\n        templateParticipantMailWaitlist {\n          subject\n          template\n        }\n      }\n    }\n  }\n": types.MailSettingsParticipantWaitlistTemplateQueryDocument,
    "\n  mutation updateParticipantWaitlistTemplateQuery(\n    $companyId: String!\n    $company: UpdateCompanyDto!\n  ) {\n    updateCompany(companyId: $companyId, company: $company) {\n      mailSettings {\n        templateParticipantMailWaitlist {\n          subject\n          template\n        }\n      }\n    }\n  }\n": types.UpdateParticipantWaitlistTemplateQueryDocument,
    "\n  query getCompanies {\n    companiesForSessionUser {\n      user {\n        firstName\n        lastName\n        title\n        email\n        allowSevenDaysReminder\n        allowOneDayReminder\n      }\n      companiesForUser {\n        id\n        privileges\n        name\n        domain\n      }\n    }\n  }\n": types.GetCompaniesDocument,
    "\n  query seminar($companyId: String!, $seminarId: String!) {\n    seminar(companyId: $companyId, seminarId: $seminarId) {\n      title\n      startDate\n      endDate\n      registrationDate\n      canceled\n      locationText\n      price\n      price2\n      price3\n      price4\n      priceText\n      priceText2\n      priceText3\n      priceText4\n      minParticipants\n      maxParticipants\n      currentParticipants\n      description\n      description2\n      description3\n      description4\n      description5\n      visible\n      btsLocked\n      passwords\n    }\n  }\n": types.SeminarDocument,
    "\n  mutation updateSeminar(\n    $companyId: String!\n    $seminarId: String!\n    $updatedValues: UpdateSeminarDto!\n  ) {\n    updateSeminar(\n      companyId: $companyId\n      seminarId: $seminarId\n      updateValues: $updatedValues\n    )\n  }\n": types.UpdateSeminarDocument,
    "\n  query getSeminars($companyId: String!) {\n    seminars(companyId: $companyId) {\n      id\n      seminarNumber\n      title\n      subtitle\n      startDate\n      endDate\n      registrationDate\n      description\n    }\n  }\n": types.GetSeminarsDocument,
    "\n  query companySignUpCustomConditionalFields($companyId: String!) {\n    companySignUpCustomConditionalFields(companyId: $companyId) {\n      name\n      displayName\n      child {\n        name\n        displayName\n        child {\n          name\n          displayName\n          child {\n            name\n            displayName\n          }\n        }\n      }\n    }\n  }\n": types.CompanySignUpCustomConditionalFieldsDocument,
    "\n  mutation updateSignUpFormSelectFields(\n    $signUpCustomConditionalFields: SignUpCustomConditionalFieldInput\n    $companyId: String!\n  ) {\n    updateSignUpCustomConditionalFields(\n      companyId: $companyId\n      signUpCustomConditionalFields: $signUpCustomConditionalFields\n    )\n  }\n": types.UpdateSignUpFormSelectFieldsDocument,
    "\n  query companySignUpFormElements($companyId: String!) {\n    companySignUpCustomFields(companyId: $companyId) {\n      id\n      type\n      btsClass\n      btsName\n      name\n      required\n      showAfter\n      showInMail\n      systemField\n      type\n      validator\n      visibleIfField\n      visibleIfValue\n      options {\n        displayValue\n        value\n      }\n    }\n  }\n": types.CompanySignUpFormElementsDocument,
    "\n  mutation updateSignUpFormElements(\n    $signUpFormElements: [SignUpCustomFieldInput!]!\n    $companyId: String!\n  ) {\n    updateSignUpCustomFields(\n      companyId: $companyId\n      signUpCustomFields: $signUpFormElements\n    )\n  }\n": types.UpdateSignUpFormElementsDocument,
    "\n  query user($companyId: String!, $userId: String!) {\n    user(companyId: $companyId, userId: $userId) {\n      title\n      firstName\n      lastName\n      email\n      privileges\n    }\n  }\n": types.UserDocument,
    "\n  mutation updateUser(\n    $companyId: String!\n    $userId: String!\n    $updatedValues: UpdateUserDto!\n  ) {\n    updateUser(\n      companyId: $companyId\n      userId: $userId\n      updatedValues: $updatedValues\n    )\n  }\n": types.UpdateUserDocument,
    "\n  mutation createUser($user: CreateUserDto!) {\n    createUser(user: $user) {\n      id\n    }\n  }\n": types.CreateUserDocument,
    "\n  query usersAndLecturers($companyId: String!) {\n    usersAndLecturersForCompany(companyId: $companyId) {\n      privileges\n      lecturerId\n      userId\n      email\n      firstName\n      lastName\n      jobTitle\n      hasAccount\n      privileges\n    }\n  }\n": types.UsersAndLecturersDocument,
    "\n  mutation deleteUser($companyId: String!, $userId: String!) {\n    deleteUser(companyId: $companyId, userId: $userId)\n  }\n": types.DeleteUserDocument,
    "\n  mutation inviteLecturers($companyId: String!, $lecturerIds: [String!]!) {\n    inviteLecturers(companyId: $companyId, lecturerIds: $lecturerIds)\n  }\n": types.InviteLecturersDocument,
    "\n  mutation updateMyProfile($companyId: String!, $user: PublicUpdateUserDto!) {\n    publicUpdateUser(companyId: $companyId, user: $user)\n  }\n": types.UpdateMyProfileDocument,
    "\n  mutation deleteProfileImage {\n    publicDeleteProfileImage\n  }\n": types.DeleteProfileImageDocument,
    "\n  query getMyLecturerSeminars($companyId: String!) {\n    myLecturerSeminars(companyId: $companyId) {\n      seminars {\n        id\n        title\n        subtitle\n        seminarNumber\n        dateText\n        description\n        plainDescription\n        locationText\n        startDate\n        endDate\n        lecturers {\n          name\n        }\n        categories {\n          name\n        }\n      }\n      count\n    }\n  }\n": types.GetMyLecturerSeminarsDocument,
    "\n  query getMySeminars($companyId: String!) {\n    mySeminars(companyId: $companyId) {\n      seminars {\n        id\n        title\n        subtitle\n        seminarNumber\n        dateText\n        description\n        plainDescription\n        locationText\n        startDate\n        endDate\n        lecturers {\n          name\n        }\n        categories {\n          name\n        }\n      }\n      count\n    }\n  }\n": types.GetMySeminarsDocument,
    "\n  mutation publicRegisterUser($user: RegisterUserDto!) {\n    publicRegisterUser(user: $user) {\n      id\n    }\n  }\n": types.PublicRegisterUserDocument,
    "\n  query getPublicSeminar($seminarId: String!, $companyId: String!) {\n    publicSeminar(seminarId: $seminarId, companyId: $companyId) {\n      id\n      title\n      subtitle\n      seminarNumber\n      startDate\n      endDate\n      dateText\n      description\n      locationText\n      registrationDate\n      price\n      priceText\n      price2\n      priceText2\n      price3\n      priceText3\n      price4\n      priceText4\n      isBookedOut\n      isAlmostBookedOut\n      canceled\n      files {\n        blobId\n        accessPermissionLevel\n        specificSignUpId\n        pathToFile\n        uploadedAt\n        name\n        size\n      }\n      body {\n        type\n        html\n      }\n      lecturers {\n        name\n        id\n        jobTitle\n        vita\n        pathToProfileImage\n      }\n      categories {\n        name\n      }\n      signedUpAt\n      currentUserIsLecturer\n    }\n  }\n": types.GetPublicSeminarDocument,
    "\n  query getPublicSignUpsSeminar($seminarId: String!, $companyId: String!) {\n    signUpsForSeminar(seminarId: $seminarId, companyId: $companyId) {\n      firstName\n      lastName\n      email\n      id\n      linkedToAccount\n    }\n  }\n": types.GetPublicSignUpsSeminarDocument,
    "\n  mutation removeFile(\n    $companyId: String!\n    $seminarId: String!\n    $blobId: String!\n  ) {\n    removeFile(companyId: $companyId, seminarId: $seminarId, blobId: $blobId)\n  }\n": types.RemoveFileDocument,
    "\n  query getPublicSeminars(\n    $companyId: String!\n    $categoryId: String\n    $limit: Int!\n    $skip: Int!\n    $searchTerm: String\n  ) {\n    publicSeminars(\n      companyId: $companyId\n      categoryId: $categoryId\n      limit: $limit\n      skip: $skip\n      searchTerm: $searchTerm\n    ) {\n      seminars {\n        id\n        title\n        subtitle\n        seminarNumber\n        dateText\n        description\n        plainDescription\n        locationText\n        startDate\n        endDate\n        signedUpAt\n        currentUserIsLecturer\n        lecturers {\n          name\n        }\n        categories {\n          name\n        }\n      }\n      count\n    }\n  }\n": types.GetPublicSeminarsDocument,
    "\n  query getPublicSeminarForSignUp($seminarId: String!, $companyId: String!) {\n    publicSeminar(seminarId: $seminarId, companyId: $companyId) {\n      id\n      title\n      subtitle\n      seminarNumber\n      startDate\n      endDate\n      dateText\n      description\n      locationText\n      price\n      priceText\n      price2\n      priceText2\n      price3\n      priceText3\n      price4\n      priceText4\n      needsPassword\n      isBookedOut\n      isAlmostBookedOut\n      lecturers {\n        name\n      }\n      categories {\n        name\n      }\n    }\n  }\n": types.GetPublicSeminarForSignUpDocument,
    "\n  query getPublicSignUpFormElements($companyId: String!) {\n    publicCompanySignUpCustomFields(companyId: $companyId) {\n      id\n      options {\n        value\n        displayValue\n      }\n      visibleIfValue\n      visibleIfField\n      validator\n      type\n      systemField\n      required\n      name\n    }\n  }\n": types.GetPublicSignUpFormElementsDocument,
    "\n  query getPublicCompanySignUpFormGroupSelectElements($companyId: String!) {\n    publicCompanySignUpCustomConditionalFieldGroup(companyId: $companyId) {\n      fields {\n        displayName\n        name\n        child {\n          displayName\n          name\n          child {\n            displayName\n            name\n            child {\n              displayName\n              name\n            }\n          }\n        }\n      }\n      options {\n        displayName\n        value\n        children {\n          displayName\n          value\n          children {\n            displayName\n            value\n            children {\n              displayName\n              value\n            }\n          }\n        }\n      }\n    }\n  }\n": types.GetPublicCompanySignUpFormGroupSelectElementsDocument,
    "\n  mutation signUp($signUp: CreateSignUpDto!) {\n    publicSignUp(signUp: $signUp) {\n      result\n    }\n  }\n": types.SignUpDocument,
    "\n  query getPublicSites($companyId: String!, $slug: String!) {\n    publicCompanySite(companyId: $companyId, slug: $slug) {\n      title\n      slug\n      draft\n      isFooterSite\n      externalUrl\n      body\n    }\n  }\n": types.GetPublicSitesDocument,
    "\n  query getUserInfo {\n    companiesForSessionUser {\n      user {\n        title\n        firstName\n        lastName\n        id\n        email\n        allowSevenDaysReminder\n        allowOneDayReminder\n        lecturerId\n        vita\n        jobTitle\n        pathToProfileImage\n      }\n      companiesForUser {\n        id\n        privileges\n        name\n      }\n    }\n  }\n": types.GetUserInfoDocument,
    "\n  query getPublicCompany {\n    publicCompany {\n      id\n      companyName\n      functionalSettings {\n        registrationActive\n        searchActive\n        infoText\n        errorTextWrongPassword\n        waitlistActive\n        passwordActive\n        disableUserAccounts\n      }\n      visualSettings {\n        backToDashboardActive\n        backToMainActive\n        primaryColor\n        primaryTextColor\n        pathToBanner\n        showSignalLight\n      }\n      sites {\n        title\n        slug\n        isFooterSite\n        externalUrl\n      }\n      categories {\n        id\n        name\n        children {\n          id\n          name\n          children {\n            id\n            name\n            children {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n": types.GetPublicCompanyDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query companyBanner($companyId: String!) {\n    company(companyId: $companyId) {\n      visualSettings {\n        pathToBanner\n      }\n    }\n  }\n"): (typeof documents)["\n  query companyBanner($companyId: String!) {\n    company(companyId: $companyId) {\n      visualSettings {\n        pathToBanner\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation removeBanner($companyId: String!) {\n    removeBanner(companyId: $companyId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation removeBanner($companyId: String!) {\n    removeBanner(companyId: $companyId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query companyCategories($companyId: String!) {\n    companyCategories(companyId: $companyId) {\n      id\n      name\n      children {\n        id\n        name\n        children {\n          id\n          name\n          children {\n            id\n            name\n            children {\n              id\n              name\n              children {\n                id\n                name\n                children {\n                  id\n                  name\n                  children {\n                    id\n                    name\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query companyCategories($companyId: String!) {\n    companyCategories(companyId: $companyId) {\n      id\n      name\n      children {\n        id\n        name\n        children {\n          id\n          name\n          children {\n            id\n            name\n            children {\n              id\n              name\n              children {\n                id\n                name\n                children {\n                  id\n                  name\n                  children {\n                    id\n                    name\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateCategories(\n    $companyId: String!\n    $categories: [CompanyCategoryInput!]!\n  ) {\n    updateCategories(companyId: $companyId, categories: $categories)\n  }\n"): (typeof documents)["\n  mutation updateCategories(\n    $companyId: String!\n    $categories: [CompanyCategoryInput!]!\n  ) {\n    updateCategories(companyId: $companyId, categories: $categories)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query companyFlags($companyId: String!) {\n    companyFlags(companyId: $companyId) {\n      flag1\n      flag2\n      flag3\n      flag4\n      flag5\n      flag6\n      flag7\n      flag8\n      flag9\n      flag10\n      flag11\n      flag12\n      flag13\n      flag14\n      flag15\n      flag16\n      flag17\n      flag18\n      flag19\n      flag20\n    }\n  }\n"): (typeof documents)["\n  query companyFlags($companyId: String!) {\n    companyFlags(companyId: $companyId) {\n      flag1\n      flag2\n      flag3\n      flag4\n      flag5\n      flag6\n      flag7\n      flag8\n      flag9\n      flag10\n      flag11\n      flag12\n      flag13\n      flag14\n      flag15\n      flag16\n      flag17\n      flag18\n      flag19\n      flag20\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateCompanyFlags($companyId: String!, $flags: FlagsInput!) {\n    updateCompanyFlags(companyId: $companyId, flags: $flags) {\n      flag1\n      flag2\n      flag3\n      flag4\n      flag5\n      flag6\n      flag7\n      flag8\n      flag9\n      flag10\n      flag11\n      flag12\n      flag13\n      flag14\n      flag15\n      flag16\n      flag17\n      flag18\n      flag19\n      flag20\n    }\n  }\n"): (typeof documents)["\n  mutation updateCompanyFlags($companyId: String!, $flags: FlagsInput!) {\n    updateCompanyFlags(companyId: $companyId, flags: $flags) {\n      flag1\n      flag2\n      flag3\n      flag4\n      flag5\n      flag6\n      flag7\n      flag8\n      flag9\n      flag10\n      flag11\n      flag12\n      flag13\n      flag14\n      flag15\n      flag16\n      flag17\n      flag18\n      flag19\n      flag20\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query companySettings($companyId: String!) {\n    company(companyId: $companyId) {\n      visualSettings {\n        showTutor\n        showEndOfRegistrationForSeminar\n        showDescription\n        showSeminarNumber\n        showPrice\n        showDate\n        showLocation\n        showSignalLight\n        backToDashboardActive\n        backToMainActive\n        primaryColor\n        primaryTextColor\n      }\n      secret\n      active\n      domain\n      functionalSettings {\n        errorTextWrongPassword\n        infoText\n        passwordActive\n        priceCalculation\n        registrationActive\n        searchActive\n        seminarsOrderBy\n        showPastSeminars\n        waitlistActive\n        disableUserAccounts\n        showBookedOutSeminars\n      }\n    }\n  }\n"): (typeof documents)["\n  query companySettings($companyId: String!) {\n    company(companyId: $companyId) {\n      visualSettings {\n        showTutor\n        showEndOfRegistrationForSeminar\n        showDescription\n        showSeminarNumber\n        showPrice\n        showDate\n        showLocation\n        showSignalLight\n        backToDashboardActive\n        backToMainActive\n        primaryColor\n        primaryTextColor\n      }\n      secret\n      active\n      domain\n      functionalSettings {\n        errorTextWrongPassword\n        infoText\n        passwordActive\n        priceCalculation\n        registrationActive\n        searchActive\n        seminarsOrderBy\n        showPastSeminars\n        waitlistActive\n        disableUserAccounts\n        showBookedOutSeminars\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateCompanySettings(\n    $companyId: String!\n    $company: UpdateCompanyDto!\n  ) {\n    updateCompany(companyId: $companyId, company: $company) {\n      visualSettings {\n        showTutor\n        showEndOfRegistrationForSeminar\n        showDescription\n        showSeminarNumber\n        showPrice\n        showDate\n        showLocation\n        showSignalLight\n        backToDashboardActive\n        backToMainActive\n        primaryColor\n        primaryTextColor\n      }\n      active\n      domain\n      functionalSettings {\n        errorTextWrongPassword\n        infoText\n        passwordActive\n        priceCalculation\n        registrationActive\n        searchActive\n        seminarsOrderBy\n        showPastSeminars\n        waitlistActive\n        disableUserAccounts\n        showBookedOutSeminars\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateCompanySettings(\n    $companyId: String!\n    $company: UpdateCompanyDto!\n  ) {\n    updateCompany(companyId: $companyId, company: $company) {\n      visualSettings {\n        showTutor\n        showEndOfRegistrationForSeminar\n        showDescription\n        showSeminarNumber\n        showPrice\n        showDate\n        showLocation\n        showSignalLight\n        backToDashboardActive\n        backToMainActive\n        primaryColor\n        primaryTextColor\n      }\n      active\n      domain\n      functionalSettings {\n        errorTextWrongPassword\n        infoText\n        passwordActive\n        priceCalculation\n        registrationActive\n        searchActive\n        seminarsOrderBy\n        showPastSeminars\n        waitlistActive\n        disableUserAccounts\n        showBookedOutSeminars\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query company($companyId: String!) {\n    company(companyId: $companyId) {\n      mailSettings {\n        smtpHost\n        smtpPort\n        smtpUser\n        smtpPassword\n        smtpTls\n        senderName\n        senderMail\n        allMailsSendToBcc\n        sendConfirmationMail\n        sendConfirmationMailWaitlist\n        sendReminderSevenDays\n        sendReminderOneDay\n      }\n    }\n  }\n"): (typeof documents)["\n  query company($companyId: String!) {\n    company(companyId: $companyId) {\n      mailSettings {\n        smtpHost\n        smtpPort\n        smtpUser\n        smtpPassword\n        smtpTls\n        senderName\n        senderMail\n        allMailsSendToBcc\n        sendConfirmationMail\n        sendConfirmationMailWaitlist\n        sendReminderSevenDays\n        sendReminderOneDay\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation sendTestMail($companyId: String!, $receiver: String!) {\n    sendTestMail(companyId: $companyId, receiver: $receiver)\n  }\n"): (typeof documents)["\n  mutation sendTestMail($companyId: String!, $receiver: String!) {\n    sendTestMail(companyId: $companyId, receiver: $receiver)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateCompany($companyId: String!, $company: UpdateCompanyDto!) {\n    updateCompany(companyId: $companyId, company: $company) {\n      id\n      mailSettings {\n        smtpHost\n        smtpPort\n        smtpUser\n        smtpPassword\n        smtpTls\n        senderName\n        senderMail\n        allMailsSendToBcc\n        sendConfirmationMail\n        sendConfirmationMailWaitlist\n        sendReminderSevenDays\n        sendReminderOneDay\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateCompany($companyId: String!, $company: UpdateCompanyDto!) {\n    updateCompany(companyId: $companyId, company: $company) {\n      id\n      mailSettings {\n        smtpHost\n        smtpPort\n        smtpUser\n        smtpPassword\n        smtpTls\n        senderName\n        senderMail\n        allMailsSendToBcc\n        sendConfirmationMail\n        sendConfirmationMailWaitlist\n        sendReminderSevenDays\n        sendReminderOneDay\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query companyPublicSeminarPageFields($companyId: String!) {\n    companyPublicSeminarPageFields(companyId: $companyId) {\n      fieldName\n      type\n      markdown\n    }\n  }\n"): (typeof documents)["\n  query companyPublicSeminarPageFields($companyId: String!) {\n    companyPublicSeminarPageFields(companyId: $companyId) {\n      fieldName\n      type\n      markdown\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updatePublicSeminarPageFields(\n    $publicSeminarPageFields: [PublicSeminarPageFieldInput!]!\n    $companyId: String!\n  ) {\n    updatePublicSeminarPageFields(\n      companyId: $companyId\n      publicSeminarPageFields: $publicSeminarPageFields\n    )\n  }\n"): (typeof documents)["\n  mutation updatePublicSeminarPageFields(\n    $publicSeminarPageFields: [PublicSeminarPageFieldInput!]!\n    $companyId: String!\n  ) {\n    updatePublicSeminarPageFields(\n      companyId: $companyId\n      publicSeminarPageFields: $publicSeminarPageFields\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getOneSite($companyId: String!, $slug: String!) {\n    companySite(companyId: $companyId, slug: $slug) {\n      title\n      body\n      slug\n      isFooterSite\n      draft\n      externalUrl\n    }\n  }\n"): (typeof documents)["\n  query getOneSite($companyId: String!, $slug: String!) {\n    companySite(companyId: $companyId, slug: $slug) {\n      title\n      body\n      slug\n      isFooterSite\n      draft\n      externalUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateCompanySite(\n    $companyId: String!\n    $slug: String!\n    $site: SiteInput!\n  ) {\n    updateCompanySite(companyId: $companyId, slug: $slug, site: $site) {\n      slug\n      title\n      body\n      draft\n      externalUrl\n      isFooterSite\n    }\n  }\n"): (typeof documents)["\n  mutation updateCompanySite(\n    $companyId: String!\n    $slug: String!\n    $site: SiteInput!\n  ) {\n    updateCompanySite(companyId: $companyId, slug: $slug, site: $site) {\n      slug\n      title\n      body\n      draft\n      externalUrl\n      isFooterSite\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation addCompanySite($companyId: String!, $site: SiteInput!) {\n    addCompanySite(companyId: $companyId, site: $site) {\n      slug\n      title\n      body\n      draft\n      externalUrl\n      isFooterSite\n    }\n  }\n"): (typeof documents)["\n  mutation addCompanySite($companyId: String!, $site: SiteInput!) {\n    addCompanySite(companyId: $companyId, site: $site) {\n      slug\n      title\n      body\n      draft\n      externalUrl\n      isFooterSite\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query companySites($companyId: String!) {\n    companySites(companyId: $companyId) {\n      title\n      slug\n      draft\n      externalUrl\n    }\n  }\n"): (typeof documents)["\n  query companySites($companyId: String!) {\n    companySites(companyId: $companyId) {\n      title\n      slug\n      draft\n      externalUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteCompanySite($companyId: String!, $slug: String!) {\n    deleteCompanySite(companyId: $companyId, slug: $slug)\n  }\n"): (typeof documents)["\n  mutation deleteCompanySite($companyId: String!, $slug: String!) {\n    deleteCompanySite(companyId: $companyId, slug: $slug)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation previewMjml($companyId: String!, $mjmlTemplate: String!) {\n    previewMjml(companyId: $companyId, mjmlTemplate: $mjmlTemplate) {\n      body\n    }\n  }\n"): (typeof documents)["\n  mutation previewMjml($companyId: String!, $mjmlTemplate: String!) {\n    previewMjml(companyId: $companyId, mjmlTemplate: $mjmlTemplate) {\n      body\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query mailOneDayTemplate($companyId: String!) {\n    company(companyId: $companyId) {\n      mailSettings {\n        mailTemplateReminderOneDay {\n          subject\n          template\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query mailOneDayTemplate($companyId: String!) {\n    company(companyId: $companyId) {\n      mailSettings {\n        mailTemplateReminderOneDay {\n          subject\n          template\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateTemplateOneDay(\n    $companyId: String!\n    $company: UpdateCompanyDto!\n  ) {\n    updateCompany(companyId: $companyId, company: $company) {\n      mailSettings {\n        mailTemplateReminderOneDay {\n          subject\n          template\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateTemplateOneDay(\n    $companyId: String!\n    $company: UpdateCompanyDto!\n  ) {\n    updateCompany(companyId: $companyId, company: $company) {\n      mailSettings {\n        mailTemplateReminderOneDay {\n          subject\n          template\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query mailSevenDays($companyId: String!) {\n    company(companyId: $companyId) {\n      mailSettings {\n        mailTemplateReminderSevenDays {\n          subject\n          template\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query mailSevenDays($companyId: String!) {\n    company(companyId: $companyId) {\n      mailSettings {\n        mailTemplateReminderSevenDays {\n          subject\n          template\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateTemplateSevenDays(\n    $companyId: String!\n    $company: UpdateCompanyDto!\n  ) {\n    updateCompany(companyId: $companyId, company: $company) {\n      mailSettings {\n        mailTemplateReminderSevenDays {\n          subject\n          template\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateTemplateSevenDays(\n    $companyId: String!\n    $company: UpdateCompanyDto!\n  ) {\n    updateCompany(companyId: $companyId, company: $company) {\n      mailSettings {\n        mailTemplateReminderSevenDays {\n          subject\n          template\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query mailSettingsParticipantTemplateQuery($companyId: String!) {\n    company(companyId: $companyId) {\n      mailSettings {\n        templateParticipantMail {\n          subject\n          template\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query mailSettingsParticipantTemplateQuery($companyId: String!) {\n    company(companyId: $companyId) {\n      mailSettings {\n        templateParticipantMail {\n          subject\n          template\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateParticipantTemplateQuery(\n    $companyId: String!\n    $company: UpdateCompanyDto!\n  ) {\n    updateCompany(companyId: $companyId, company: $company) {\n      mailSettings {\n        templateParticipantMail {\n          subject\n          template\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateParticipantTemplateQuery(\n    $companyId: String!\n    $company: UpdateCompanyDto!\n  ) {\n    updateCompany(companyId: $companyId, company: $company) {\n      mailSettings {\n        templateParticipantMail {\n          subject\n          template\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query mailSettingsParticipantWaitlistTemplateQuery($companyId: String!) {\n    company(companyId: $companyId) {\n      mailSettings {\n        templateParticipantMailWaitlist {\n          subject\n          template\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query mailSettingsParticipantWaitlistTemplateQuery($companyId: String!) {\n    company(companyId: $companyId) {\n      mailSettings {\n        templateParticipantMailWaitlist {\n          subject\n          template\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateParticipantWaitlistTemplateQuery(\n    $companyId: String!\n    $company: UpdateCompanyDto!\n  ) {\n    updateCompany(companyId: $companyId, company: $company) {\n      mailSettings {\n        templateParticipantMailWaitlist {\n          subject\n          template\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateParticipantWaitlistTemplateQuery(\n    $companyId: String!\n    $company: UpdateCompanyDto!\n  ) {\n    updateCompany(companyId: $companyId, company: $company) {\n      mailSettings {\n        templateParticipantMailWaitlist {\n          subject\n          template\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getCompanies {\n    companiesForSessionUser {\n      user {\n        firstName\n        lastName\n        title\n        email\n        allowSevenDaysReminder\n        allowOneDayReminder\n      }\n      companiesForUser {\n        id\n        privileges\n        name\n        domain\n      }\n    }\n  }\n"): (typeof documents)["\n  query getCompanies {\n    companiesForSessionUser {\n      user {\n        firstName\n        lastName\n        title\n        email\n        allowSevenDaysReminder\n        allowOneDayReminder\n      }\n      companiesForUser {\n        id\n        privileges\n        name\n        domain\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query seminar($companyId: String!, $seminarId: String!) {\n    seminar(companyId: $companyId, seminarId: $seminarId) {\n      title\n      startDate\n      endDate\n      registrationDate\n      canceled\n      locationText\n      price\n      price2\n      price3\n      price4\n      priceText\n      priceText2\n      priceText3\n      priceText4\n      minParticipants\n      maxParticipants\n      currentParticipants\n      description\n      description2\n      description3\n      description4\n      description5\n      visible\n      btsLocked\n      passwords\n    }\n  }\n"): (typeof documents)["\n  query seminar($companyId: String!, $seminarId: String!) {\n    seminar(companyId: $companyId, seminarId: $seminarId) {\n      title\n      startDate\n      endDate\n      registrationDate\n      canceled\n      locationText\n      price\n      price2\n      price3\n      price4\n      priceText\n      priceText2\n      priceText3\n      priceText4\n      minParticipants\n      maxParticipants\n      currentParticipants\n      description\n      description2\n      description3\n      description4\n      description5\n      visible\n      btsLocked\n      passwords\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateSeminar(\n    $companyId: String!\n    $seminarId: String!\n    $updatedValues: UpdateSeminarDto!\n  ) {\n    updateSeminar(\n      companyId: $companyId\n      seminarId: $seminarId\n      updateValues: $updatedValues\n    )\n  }\n"): (typeof documents)["\n  mutation updateSeminar(\n    $companyId: String!\n    $seminarId: String!\n    $updatedValues: UpdateSeminarDto!\n  ) {\n    updateSeminar(\n      companyId: $companyId\n      seminarId: $seminarId\n      updateValues: $updatedValues\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getSeminars($companyId: String!) {\n    seminars(companyId: $companyId) {\n      id\n      seminarNumber\n      title\n      subtitle\n      startDate\n      endDate\n      registrationDate\n      description\n    }\n  }\n"): (typeof documents)["\n  query getSeminars($companyId: String!) {\n    seminars(companyId: $companyId) {\n      id\n      seminarNumber\n      title\n      subtitle\n      startDate\n      endDate\n      registrationDate\n      description\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query companySignUpCustomConditionalFields($companyId: String!) {\n    companySignUpCustomConditionalFields(companyId: $companyId) {\n      name\n      displayName\n      child {\n        name\n        displayName\n        child {\n          name\n          displayName\n          child {\n            name\n            displayName\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query companySignUpCustomConditionalFields($companyId: String!) {\n    companySignUpCustomConditionalFields(companyId: $companyId) {\n      name\n      displayName\n      child {\n        name\n        displayName\n        child {\n          name\n          displayName\n          child {\n            name\n            displayName\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateSignUpFormSelectFields(\n    $signUpCustomConditionalFields: SignUpCustomConditionalFieldInput\n    $companyId: String!\n  ) {\n    updateSignUpCustomConditionalFields(\n      companyId: $companyId\n      signUpCustomConditionalFields: $signUpCustomConditionalFields\n    )\n  }\n"): (typeof documents)["\n  mutation updateSignUpFormSelectFields(\n    $signUpCustomConditionalFields: SignUpCustomConditionalFieldInput\n    $companyId: String!\n  ) {\n    updateSignUpCustomConditionalFields(\n      companyId: $companyId\n      signUpCustomConditionalFields: $signUpCustomConditionalFields\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query companySignUpFormElements($companyId: String!) {\n    companySignUpCustomFields(companyId: $companyId) {\n      id\n      type\n      btsClass\n      btsName\n      name\n      required\n      showAfter\n      showInMail\n      systemField\n      type\n      validator\n      visibleIfField\n      visibleIfValue\n      options {\n        displayValue\n        value\n      }\n    }\n  }\n"): (typeof documents)["\n  query companySignUpFormElements($companyId: String!) {\n    companySignUpCustomFields(companyId: $companyId) {\n      id\n      type\n      btsClass\n      btsName\n      name\n      required\n      showAfter\n      showInMail\n      systemField\n      type\n      validator\n      visibleIfField\n      visibleIfValue\n      options {\n        displayValue\n        value\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateSignUpFormElements(\n    $signUpFormElements: [SignUpCustomFieldInput!]!\n    $companyId: String!\n  ) {\n    updateSignUpCustomFields(\n      companyId: $companyId\n      signUpCustomFields: $signUpFormElements\n    )\n  }\n"): (typeof documents)["\n  mutation updateSignUpFormElements(\n    $signUpFormElements: [SignUpCustomFieldInput!]!\n    $companyId: String!\n  ) {\n    updateSignUpCustomFields(\n      companyId: $companyId\n      signUpCustomFields: $signUpFormElements\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query user($companyId: String!, $userId: String!) {\n    user(companyId: $companyId, userId: $userId) {\n      title\n      firstName\n      lastName\n      email\n      privileges\n    }\n  }\n"): (typeof documents)["\n  query user($companyId: String!, $userId: String!) {\n    user(companyId: $companyId, userId: $userId) {\n      title\n      firstName\n      lastName\n      email\n      privileges\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateUser(\n    $companyId: String!\n    $userId: String!\n    $updatedValues: UpdateUserDto!\n  ) {\n    updateUser(\n      companyId: $companyId\n      userId: $userId\n      updatedValues: $updatedValues\n    )\n  }\n"): (typeof documents)["\n  mutation updateUser(\n    $companyId: String!\n    $userId: String!\n    $updatedValues: UpdateUserDto!\n  ) {\n    updateUser(\n      companyId: $companyId\n      userId: $userId\n      updatedValues: $updatedValues\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createUser($user: CreateUserDto!) {\n    createUser(user: $user) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation createUser($user: CreateUserDto!) {\n    createUser(user: $user) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query usersAndLecturers($companyId: String!) {\n    usersAndLecturersForCompany(companyId: $companyId) {\n      privileges\n      lecturerId\n      userId\n      email\n      firstName\n      lastName\n      jobTitle\n      hasAccount\n      privileges\n    }\n  }\n"): (typeof documents)["\n  query usersAndLecturers($companyId: String!) {\n    usersAndLecturersForCompany(companyId: $companyId) {\n      privileges\n      lecturerId\n      userId\n      email\n      firstName\n      lastName\n      jobTitle\n      hasAccount\n      privileges\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteUser($companyId: String!, $userId: String!) {\n    deleteUser(companyId: $companyId, userId: $userId)\n  }\n"): (typeof documents)["\n  mutation deleteUser($companyId: String!, $userId: String!) {\n    deleteUser(companyId: $companyId, userId: $userId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation inviteLecturers($companyId: String!, $lecturerIds: [String!]!) {\n    inviteLecturers(companyId: $companyId, lecturerIds: $lecturerIds)\n  }\n"): (typeof documents)["\n  mutation inviteLecturers($companyId: String!, $lecturerIds: [String!]!) {\n    inviteLecturers(companyId: $companyId, lecturerIds: $lecturerIds)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateMyProfile($companyId: String!, $user: PublicUpdateUserDto!) {\n    publicUpdateUser(companyId: $companyId, user: $user)\n  }\n"): (typeof documents)["\n  mutation updateMyProfile($companyId: String!, $user: PublicUpdateUserDto!) {\n    publicUpdateUser(companyId: $companyId, user: $user)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteProfileImage {\n    publicDeleteProfileImage\n  }\n"): (typeof documents)["\n  mutation deleteProfileImage {\n    publicDeleteProfileImage\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getMyLecturerSeminars($companyId: String!) {\n    myLecturerSeminars(companyId: $companyId) {\n      seminars {\n        id\n        title\n        subtitle\n        seminarNumber\n        dateText\n        description\n        plainDescription\n        locationText\n        startDate\n        endDate\n        lecturers {\n          name\n        }\n        categories {\n          name\n        }\n      }\n      count\n    }\n  }\n"): (typeof documents)["\n  query getMyLecturerSeminars($companyId: String!) {\n    myLecturerSeminars(companyId: $companyId) {\n      seminars {\n        id\n        title\n        subtitle\n        seminarNumber\n        dateText\n        description\n        plainDescription\n        locationText\n        startDate\n        endDate\n        lecturers {\n          name\n        }\n        categories {\n          name\n        }\n      }\n      count\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getMySeminars($companyId: String!) {\n    mySeminars(companyId: $companyId) {\n      seminars {\n        id\n        title\n        subtitle\n        seminarNumber\n        dateText\n        description\n        plainDescription\n        locationText\n        startDate\n        endDate\n        lecturers {\n          name\n        }\n        categories {\n          name\n        }\n      }\n      count\n    }\n  }\n"): (typeof documents)["\n  query getMySeminars($companyId: String!) {\n    mySeminars(companyId: $companyId) {\n      seminars {\n        id\n        title\n        subtitle\n        seminarNumber\n        dateText\n        description\n        plainDescription\n        locationText\n        startDate\n        endDate\n        lecturers {\n          name\n        }\n        categories {\n          name\n        }\n      }\n      count\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation publicRegisterUser($user: RegisterUserDto!) {\n    publicRegisterUser(user: $user) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation publicRegisterUser($user: RegisterUserDto!) {\n    publicRegisterUser(user: $user) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPublicSeminar($seminarId: String!, $companyId: String!) {\n    publicSeminar(seminarId: $seminarId, companyId: $companyId) {\n      id\n      title\n      subtitle\n      seminarNumber\n      startDate\n      endDate\n      dateText\n      description\n      locationText\n      registrationDate\n      price\n      priceText\n      price2\n      priceText2\n      price3\n      priceText3\n      price4\n      priceText4\n      isBookedOut\n      isAlmostBookedOut\n      canceled\n      files {\n        blobId\n        accessPermissionLevel\n        specificSignUpId\n        pathToFile\n        uploadedAt\n        name\n        size\n      }\n      body {\n        type\n        html\n      }\n      lecturers {\n        name\n        id\n        jobTitle\n        vita\n        pathToProfileImage\n      }\n      categories {\n        name\n      }\n      signedUpAt\n      currentUserIsLecturer\n    }\n  }\n"): (typeof documents)["\n  query getPublicSeminar($seminarId: String!, $companyId: String!) {\n    publicSeminar(seminarId: $seminarId, companyId: $companyId) {\n      id\n      title\n      subtitle\n      seminarNumber\n      startDate\n      endDate\n      dateText\n      description\n      locationText\n      registrationDate\n      price\n      priceText\n      price2\n      priceText2\n      price3\n      priceText3\n      price4\n      priceText4\n      isBookedOut\n      isAlmostBookedOut\n      canceled\n      files {\n        blobId\n        accessPermissionLevel\n        specificSignUpId\n        pathToFile\n        uploadedAt\n        name\n        size\n      }\n      body {\n        type\n        html\n      }\n      lecturers {\n        name\n        id\n        jobTitle\n        vita\n        pathToProfileImage\n      }\n      categories {\n        name\n      }\n      signedUpAt\n      currentUserIsLecturer\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPublicSignUpsSeminar($seminarId: String!, $companyId: String!) {\n    signUpsForSeminar(seminarId: $seminarId, companyId: $companyId) {\n      firstName\n      lastName\n      email\n      id\n      linkedToAccount\n    }\n  }\n"): (typeof documents)["\n  query getPublicSignUpsSeminar($seminarId: String!, $companyId: String!) {\n    signUpsForSeminar(seminarId: $seminarId, companyId: $companyId) {\n      firstName\n      lastName\n      email\n      id\n      linkedToAccount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation removeFile(\n    $companyId: String!\n    $seminarId: String!\n    $blobId: String!\n  ) {\n    removeFile(companyId: $companyId, seminarId: $seminarId, blobId: $blobId)\n  }\n"): (typeof documents)["\n  mutation removeFile(\n    $companyId: String!\n    $seminarId: String!\n    $blobId: String!\n  ) {\n    removeFile(companyId: $companyId, seminarId: $seminarId, blobId: $blobId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPublicSeminars(\n    $companyId: String!\n    $categoryId: String\n    $limit: Int!\n    $skip: Int!\n    $searchTerm: String\n  ) {\n    publicSeminars(\n      companyId: $companyId\n      categoryId: $categoryId\n      limit: $limit\n      skip: $skip\n      searchTerm: $searchTerm\n    ) {\n      seminars {\n        id\n        title\n        subtitle\n        seminarNumber\n        dateText\n        description\n        plainDescription\n        locationText\n        startDate\n        endDate\n        signedUpAt\n        currentUserIsLecturer\n        lecturers {\n          name\n        }\n        categories {\n          name\n        }\n      }\n      count\n    }\n  }\n"): (typeof documents)["\n  query getPublicSeminars(\n    $companyId: String!\n    $categoryId: String\n    $limit: Int!\n    $skip: Int!\n    $searchTerm: String\n  ) {\n    publicSeminars(\n      companyId: $companyId\n      categoryId: $categoryId\n      limit: $limit\n      skip: $skip\n      searchTerm: $searchTerm\n    ) {\n      seminars {\n        id\n        title\n        subtitle\n        seminarNumber\n        dateText\n        description\n        plainDescription\n        locationText\n        startDate\n        endDate\n        signedUpAt\n        currentUserIsLecturer\n        lecturers {\n          name\n        }\n        categories {\n          name\n        }\n      }\n      count\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPublicSeminarForSignUp($seminarId: String!, $companyId: String!) {\n    publicSeminar(seminarId: $seminarId, companyId: $companyId) {\n      id\n      title\n      subtitle\n      seminarNumber\n      startDate\n      endDate\n      dateText\n      description\n      locationText\n      price\n      priceText\n      price2\n      priceText2\n      price3\n      priceText3\n      price4\n      priceText4\n      needsPassword\n      isBookedOut\n      isAlmostBookedOut\n      lecturers {\n        name\n      }\n      categories {\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query getPublicSeminarForSignUp($seminarId: String!, $companyId: String!) {\n    publicSeminar(seminarId: $seminarId, companyId: $companyId) {\n      id\n      title\n      subtitle\n      seminarNumber\n      startDate\n      endDate\n      dateText\n      description\n      locationText\n      price\n      priceText\n      price2\n      priceText2\n      price3\n      priceText3\n      price4\n      priceText4\n      needsPassword\n      isBookedOut\n      isAlmostBookedOut\n      lecturers {\n        name\n      }\n      categories {\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPublicSignUpFormElements($companyId: String!) {\n    publicCompanySignUpCustomFields(companyId: $companyId) {\n      id\n      options {\n        value\n        displayValue\n      }\n      visibleIfValue\n      visibleIfField\n      validator\n      type\n      systemField\n      required\n      name\n    }\n  }\n"): (typeof documents)["\n  query getPublicSignUpFormElements($companyId: String!) {\n    publicCompanySignUpCustomFields(companyId: $companyId) {\n      id\n      options {\n        value\n        displayValue\n      }\n      visibleIfValue\n      visibleIfField\n      validator\n      type\n      systemField\n      required\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPublicCompanySignUpFormGroupSelectElements($companyId: String!) {\n    publicCompanySignUpCustomConditionalFieldGroup(companyId: $companyId) {\n      fields {\n        displayName\n        name\n        child {\n          displayName\n          name\n          child {\n            displayName\n            name\n            child {\n              displayName\n              name\n            }\n          }\n        }\n      }\n      options {\n        displayName\n        value\n        children {\n          displayName\n          value\n          children {\n            displayName\n            value\n            children {\n              displayName\n              value\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getPublicCompanySignUpFormGroupSelectElements($companyId: String!) {\n    publicCompanySignUpCustomConditionalFieldGroup(companyId: $companyId) {\n      fields {\n        displayName\n        name\n        child {\n          displayName\n          name\n          child {\n            displayName\n            name\n            child {\n              displayName\n              name\n            }\n          }\n        }\n      }\n      options {\n        displayName\n        value\n        children {\n          displayName\n          value\n          children {\n            displayName\n            value\n            children {\n              displayName\n              value\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation signUp($signUp: CreateSignUpDto!) {\n    publicSignUp(signUp: $signUp) {\n      result\n    }\n  }\n"): (typeof documents)["\n  mutation signUp($signUp: CreateSignUpDto!) {\n    publicSignUp(signUp: $signUp) {\n      result\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPublicSites($companyId: String!, $slug: String!) {\n    publicCompanySite(companyId: $companyId, slug: $slug) {\n      title\n      slug\n      draft\n      isFooterSite\n      externalUrl\n      body\n    }\n  }\n"): (typeof documents)["\n  query getPublicSites($companyId: String!, $slug: String!) {\n    publicCompanySite(companyId: $companyId, slug: $slug) {\n      title\n      slug\n      draft\n      isFooterSite\n      externalUrl\n      body\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getUserInfo {\n    companiesForSessionUser {\n      user {\n        title\n        firstName\n        lastName\n        id\n        email\n        allowSevenDaysReminder\n        allowOneDayReminder\n        lecturerId\n        vita\n        jobTitle\n        pathToProfileImage\n      }\n      companiesForUser {\n        id\n        privileges\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query getUserInfo {\n    companiesForSessionUser {\n      user {\n        title\n        firstName\n        lastName\n        id\n        email\n        allowSevenDaysReminder\n        allowOneDayReminder\n        lecturerId\n        vita\n        jobTitle\n        pathToProfileImage\n      }\n      companiesForUser {\n        id\n        privileges\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPublicCompany {\n    publicCompany {\n      id\n      companyName\n      functionalSettings {\n        registrationActive\n        searchActive\n        infoText\n        errorTextWrongPassword\n        waitlistActive\n        passwordActive\n        disableUserAccounts\n      }\n      visualSettings {\n        backToDashboardActive\n        backToMainActive\n        primaryColor\n        primaryTextColor\n        pathToBanner\n        showSignalLight\n      }\n      sites {\n        title\n        slug\n        isFooterSite\n        externalUrl\n      }\n      categories {\n        id\n        name\n        children {\n          id\n          name\n          children {\n            id\n            name\n            children {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getPublicCompany {\n    publicCompany {\n      id\n      companyName\n      functionalSettings {\n        registrationActive\n        searchActive\n        infoText\n        errorTextWrongPassword\n        waitlistActive\n        passwordActive\n        disableUserAccounts\n      }\n      visualSettings {\n        backToDashboardActive\n        backToMainActive\n        primaryColor\n        primaryTextColor\n        pathToBanner\n        showSignalLight\n      }\n      sites {\n        title\n        slug\n        isFooterSite\n        externalUrl\n      }\n      categories {\n        id\n        name\n        children {\n          id\n          name\n          children {\n            id\n            name\n            children {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;