import { useLocationProperty } from 'wouter/use-browser-location';

export const useSearchParameter = () => {
  //workaround see https://github.com/molefrog/wouter/issues/393
  const searchStringFromUrl = useLocationProperty(
    () => location.search,
    () => '',
  );
  return new URLSearchParams(searchStringFromUrl).get('search');
};
