import { useFieldArray, useForm } from 'react-hook-form';
import { InputStateWrapper as HookedInput } from '../../admin/form-components/InputStateWrapper.tsx';
import { Button } from '@progress/kendo-react-buttons';
import React from 'react';
import { PageTitle } from '../PageTitle.tsx';
import { Fieldset } from '../form-components/Fieldset.tsx';
import { DropdownStateWrapper } from '../form-components/DropdownStateWrapper.tsx';
import { HeroIcon } from '../../shared/HeroIcon.tsx';
import { CheckboxStateWrapper } from '../form-components/CheckboxStateWrapper.tsx';
import {
  SignUpCustomField,
  SignUpCustomFieldInput,
  SignUpCustomFieldType,
  SignUpCustomFieldValidator,
} from '../../gql/graphql.ts';

interface SignUpFormElementFormProps {
  value?: SignUpCustomFieldInput;
  allFields: SignUpCustomField[];
  onSubmit: (s: SignUpCustomFieldInput) => void;
}

export const SignUpFormElementForm: React.FC<SignUpFormElementFormProps> = ({
  value,
  allFields,
  onSubmit,
}) => {
  const { control, formState, handleSubmit, watch } =
    useForm<SignUpCustomFieldInput>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      values: value,
    });

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'options',
  });

  const onFormSubmit = (values: SignUpCustomFieldInput) => {
    onSubmit(values);
  };

  const watchVisibleIfField = watch('visibleIfField');
  const watchType = watch('type');

  const { isValid, isDirty } = formState;

  return (
    <>
      <PageTitle title="Feld bearbeiten" />
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="k-form k-form-md relative flex w-full flex-col rounded-md bg-white shadow lg:w-full"
      >
        <Fieldset
          legend="Eingabefeld"
          description="Bearbeiten Sie ein Eingabefeld im Anmeldeformular"
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <HookedInput
              name={'name'}
              label={'Name'}
              control={control}
              rules={{ required: true }}
              hint={'Name, der in der Onlineanmeldung angezeigt wird'}
            />
            <CheckboxStateWrapper
              name={'showInMail'}
              control={control}
              label={'In Bestätigungsmail anzeigen'}
            />
            <div className="flex gap-4">
              <DropdownStateWrapper
                options={allFields
                  .filter(
                    (field) => field.type === SignUpCustomFieldType.Select,
                  )
                  .map((field) => ({
                    text: field.name,
                    value: field.id,
                  }))}
                name={'visibleIfField'}
                label={'Wenn Feld'}
                control={control}
              />
              {watchVisibleIfField && (
                <DropdownStateWrapper
                  options={
                    allFields
                      ?.find((field) => field.id === watchVisibleIfField)
                      ?.options.map((opt) => ({
                        text: opt.displayValue,
                        value: opt.value,
                      })) ?? []
                  }
                  name={'visibleIfValue'}
                  label={'Wenn Wert'}
                  control={control}
                />
              )}
            </div>
            <HookedInput
              name={'btsName'}
              label={'BTS-Name'}
              control={control}
              hint={
                'Name des Feldes der in BTS angezeigt wird. Momentan noch nicht für alle Feldtypen unterstützt.'
              }
            />
            <HookedInput
              name={'btsClass'}
              label={'BTS-Klasse'}
              control={control}
              hint={
                'Name der Datenklasse in BTS, in welche der Wert übernommen werden soll.'
              }
            />
            <DropdownStateWrapper
              options={[
                { value: SignUpCustomFieldType.Check, text: 'Checkbox' },
                {
                  value: SignUpCustomFieldType.Date,
                  text: 'Datumseingabe',
                },
                { value: SignUpCustomFieldType.Label, text: 'Label' },
                { value: SignUpCustomFieldType.Spacer, text: 'Spacer' },
                { value: SignUpCustomFieldType.Select, text: 'Select' },
                { value: SignUpCustomFieldType.Text, text: 'Text' },
                {
                  value: SignUpCustomFieldType.LongText,
                  text: 'Textarea',
                },
              ]}
              name={'type'}
              rules={{ required: true }}
              label={'Feldtyp'}
              control={control}
            />
            <CheckboxStateWrapper
              name={'required'}
              control={control}
              label={'Pflichtfeld'}
              readOnly={
                watchType === SignUpCustomFieldType.Label ||
                watchType === SignUpCustomFieldType.Spacer
              }
            />

            {watchType === SignUpCustomFieldType.Text && (
              <DropdownStateWrapper
                options={[
                  { value: SignUpCustomFieldValidator.Bic, text: 'BIC' },
                  {
                    value: SignUpCustomFieldValidator.Iban,
                    text: 'IBAN',
                  },
                ]}
                name={'validator'}
                label={'Validierung'}
                control={control}
              />
            )}
            <CheckboxStateWrapper
              name={'systemField'}
              control={control}
              label={'Feld verstecken'}
            />
            {watchType === SignUpCustomFieldType.Select && (
              <div>
                <h3>Auswahlmöglichkeiten</h3>
                <Button
                  fillMode={'outline'}
                  className="k-button k-button-md k-rounded-md k-button-outline k-button-outline-secondary mt-2"
                  type={'button'}
                  themeColor={'secondary'}
                  title="Option hinzufügen"
                  onClick={() => append({ value: '', displayValue: '' })}
                >
                  <div className="flex items-center gap-2">
                    <HeroIcon name="Plus" className="block h-5 w-5" />
                    Option hinzufügen
                  </div>
                </Button>
                <div className="mt-4 flex flex-col gap-4">
                  {fields.map((field, i) => (
                    <div className={'flex gap-2'} key={field.id}>
                      <HookedInput
                        name={`options.${i}.displayValue`}
                        label={'Text'}
                        control={control}
                      />
                      <HookedInput
                        name={`options.${i}.value`}
                        label={'Wert'}
                        control={control}
                      />
                      <div className="flex h-[38px] items-center justify-center self-end">
                        <Button
                          title="Option löschen"
                          fillMode={'outline'}
                          type={'button'}
                          themeColor={'secondary'}
                          className="h-full"
                          onClick={() => remove(i)}
                        >
                          <HeroIcon name="Trash" className="block h-5 w-5" />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Fieldset>

        <div className="k-form-buttons !mt-0 justify-end rounded-md bg-gray-50 !px-8 !py-3 !text-right">
          <Button
            type={'submit'}
            themeColor={'primary'}
            className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
            disabled={!isValid || !isDirty}
            title="Speichern"
          >
            <HeroIcon name="CheckCircle" className="block h-5 w-5" />
            <div>Speichern</div>
          </Button>
        </div>
      </form>
    </>
  );
};
