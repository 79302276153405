import { useMutation, useQuery } from 'urql';
import { Site } from '../../gql/graphql.ts';
import React from 'react';
import { graphql } from '../../gql/index.ts';
import { useLocation } from 'wouter';
import { CompanySiteForm } from './CompanySiteForm.tsx';
import { cloneAndRemoveTypename } from '../../public/util.ts';

interface CompanySiteEditProps {
  companyId: string;
  slug: string;
}

const SiteQuery = graphql(/* GraphQL */ `
  query getOneSite($companyId: String!, $slug: String!) {
    companySite(companyId: $companyId, slug: $slug) {
      title
      body
      slug
      isFooterSite
      draft
      externalUrl
    }
  }
`);

const UpdateSiteMutation = graphql(/* GraphQL */ `
  mutation updateCompanySite(
    $companyId: String!
    $slug: String!
    $site: SiteInput!
  ) {
    updateCompanySite(companyId: $companyId, slug: $slug, site: $site) {
      slug
      title
      body
      draft
      externalUrl
      isFooterSite
    }
  }
`);

export const CompanySiteEditPage: React.FC<CompanySiteEditProps> = ({
  companyId,
  slug,
}) => {
  // TODO: Error handling
  const [{ data }] = useQuery({
    query: SiteQuery,
    variables: { companyId, slug },
    pause: companyId === undefined || companyId === null || !slug,
  });

  const [, updateCompany] = useMutation(UpdateSiteMutation);
  const [, setLocation] = useLocation();

  const onFormSubmit = (value: Site) => {
    updateCompany({ companyId, site: value, slug: slug }).then(() => {
      setLocation('/sites');
    });
  };

  return (
    <CompanySiteForm
      value={data?.companySite && cloneAndRemoveTypename(data?.companySite)}
      onSubmit={onFormSubmit}
    />
  );
};
