import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { useLocation } from 'wouter';
import type { PublicCompanyData } from './contexts/PublicCompanyProvider';

interface CategorySiteTreeProps {
  company: PublicCompanyData;
  hideCategories?: boolean;
  hideSites?: boolean;
}

export const CategorySiteTree: React.FC<CategorySiteTreeProps> = ({
  company,
  hideCategories,
  hideSites,
}) => {
  const [location, setLocation] = useLocation();
  const commonClasses =
    '[&>span.k-link>.k-panelbar-toggle]:hidden [&>.k-link>.k-panelbar-item-text]:text-lg [&_.k-svg-icon]:w-5 [&_.k-svg-icon]:h-5';

  const sidebarSitesMenuItems = company.sites.filter(
    (site) => !site.isFooterSite,
  );

  return (
    ((Boolean(company.categories?.length) && !hideCategories) ||
      (Boolean(sidebarSitesMenuItems.length) && !hideSites)) && (
      <PanelBar
        onSelect={(e) => {
          if (e.target.props.topLevel) {
            return;
          }
          if (e.target.props.target) {
            setLocation(e.target.props.target);
          }
        }}
        className="rounded-md [&_.k-animation-container]:rounded-none [&_.k-link.k-selected>.k-icon>svg]:text-white [&_.k-link.k-selected]:!text-white [&_.k-link>.k-icon>svg]:text-bts-theme-primary [&_.k-link]:!text-bts-theme-text"
      >
        {Boolean(company.categories?.length) && !hideCategories && (
          <PanelBarItem
            className={`!rounded-t-md [&>.k-link]:rounded-t-md ${commonClasses}`}
            title={'Kategorien'}
            expanded={true}
            svgIcon={{
              name: 'tag',
              content:
                '<path stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z" /><path stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6Z" />',
              viewBox: '0 0 24 24',
            }}
          >
            {company.categories?.map((category) => (
              <PanelBarItem
                key={category.id}
                id={'/category/' + category.id}
                selected={location === '/category/' + category.id}
                title={category.name}
                target={'/category/' + category.id}
              >
                {category.children.length > 0 &&
                  category.children.map((childCategory) => {
                    return (
                      <PanelBarItem
                        key={childCategory.id}
                        id={childCategory.id}
                        title={childCategory.name}
                        target={'/category/' + childCategory.id}
                      />
                    );
                  })}
              </PanelBarItem>
            ))}
          </PanelBarItem>
        )}
        {Boolean(sidebarSitesMenuItems.length) && !hideSites && (
          <PanelBarItem
            title="Seiten"
            className={
              commonClasses +
              ' aria-not-expanded:[&_.k-link.k-selected]:!rounded-b'
              // ' [&_.k-link.k-selected]:!rounded-b-md aria-expanded:[&_.k-link.k-selected]:!rounded-b-none'
            }
            expanded={true}
            topLevel={true}
            svgIcon={{
              name: 'globe',
              content:
                '<path fill-rule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-1.5 0a6.5 6.5 0 1 1-11-4.69v.447a3.5 3.5 0 0 0 1.025 2.475L8.293 10 8 10.293a1 1 0 0 0 0 1.414l1.06 1.06a1.5 1.5 0 0 1 .44 1.061v.363a1 1 0 0 0 .553.894l.276.139a1 1 0 0 0 1.342-.448l1.454-2.908a1.5 1.5 0 0 0-.281-1.731l-.772-.772a1 1 0 0 0-1.023-.242l-.384.128a.5.5 0 0 1-.606-.25l-.296-.592a.481.481 0 0 1 .646-.646l.262.131a1 1 0 0 0 .447.106h.188a1 1 0 0 0 .949-1.316l-.068-.204a.5.5 0 0 1 .149-.538l1.44-1.234A6.492 6.492 0 0 1 16.5 10Z" clip-rule="evenodd" />',
              viewBox: '0 0 20 20',
            }}
          >
            {sidebarSitesMenuItems?.map((site, i) => {
              const isLastItem = i === company.sites.length - 1;
              return (
                <PanelBarItem
                  className={`${isLastItem ? 'rounded-b [&>.k-link.k-selected]:rounded-b' : ''}`}
                  key={site.slug}
                  id={'/site/' + site.slug}
                  target={'/site/' + site.slug}
                  title={site.title}
                  selected={location === '/site/' + site.slug}
                />
              );
            })}
          </PanelBarItem>
        )}
      </PanelBar>
    )
  );
};
