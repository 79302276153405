import { useMutation } from 'urql';
import { ModulePrivileges, PersonTitle } from '../../gql/graphql.ts';
import React from 'react';
import { graphql } from '../../gql/index.ts';
import { useLocation } from 'wouter';
import { UserDto, UserForm } from './UserForm.tsx';

interface UserNewPageProps {
  companyId: string;
}

const AddUserMutation = graphql(/* GraphQL */ `
  mutation createUser($user: CreateUserDto!) {
    createUser(user: $user) {
      id
    }
  }
`);

export const UserNewPage: React.FC<UserNewPageProps> = ({ companyId }) => {
  const [, addUser] = useMutation(AddUserMutation);
  const [, setLocation] = useLocation();

  const onFormSubmit = (value: UserDto) => {
    addUser({
      user: {
        ...value,
        companyId,
        password: value.password ?? 'ERROR',
      },
    }).then(() => {
      setLocation('/users');
    });
  };

  return (
    <UserForm
      value={{
        title: PersonTitle.Mr,
        email: '',
        privileges: [ModulePrivileges.Member],
      }}
      onSubmit={(s) => onFormSubmit(s)}
      showPassword={true}
    />
  );
};
