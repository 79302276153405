import { useMutation, useQuery } from 'urql';
import React, { useEffect, useState } from 'react';
import { graphql } from '../gql/index.ts';
import { Button } from '@progress/kendo-react-buttons';
import {
  extendDataItem,
  mapTree,
  TreeList,
  TreeListExpandChangeEvent,
} from '@progress/kendo-react-treelist';
import { CompanyCategory } from '../gql/graphql.ts';
import { PageTitle } from './PageTitle.tsx';
import { Fieldset } from './form-components/Fieldset.tsx';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import { cloneAndRemoveTypename } from '../public/util.ts';

interface CompanyCategoriesPageProps {
  companyId: string;
}

const CompanyCategoriesQuery = graphql(/* GraphQL */ `
  query companyCategories($companyId: String!) {
    companyCategories(companyId: $companyId) {
      id
      name
      children {
        id
        name
        children {
          id
          name
          children {
            id
            name
            children {
              id
              name
              children {
                id
                name
                children {
                  id
                  name
                  children {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`);

const UpdateCategoriesMutation = graphql(/* GraphQL */ `
  mutation updateCategories(
    $companyId: String!
    $categories: [CompanyCategoryInput!]!
  ) {
    updateCategories(companyId: $companyId, categories: $categories)
  }
`);

const subItemsField: string = 'children';
const expandField: string = 'expanded';

type CompanyCategoryWithParent = CompanyCategory & { parent?: CompanyCategory };
export const CompanyCategoriesPage: React.FC<CompanyCategoriesPageProps> = ({
  companyId,
}) => {
  // TODO: Error handling
  const [{ data, fetching }] = useQuery({
    query: CompanyCategoriesQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });

  const [, updateCategories] = useMutation(UpdateCategoriesMutation);

  const [listState, setListState] = useState<{
    data: CompanyCategoryWithParent[];
    expanded: string[];
  }>({
    data: [],
    expanded: [],
  });

  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (!fetching && data) {
      setListState({
        ...listState,
        data: addParent([
          ...(cloneAndRemoveTypename(data)
            .companyCategories as CompanyCategory[]),
        ]),
      });
    }
  }, [fetching, data]);

  const onSubmit = () => {
    updateCategories({
      companyId: companyId,
      categories: removeParent(structuredClone(listState.data)),
    }).then(() => {
      setIsDirty(false);
    });
  };

  const onExpandChange = (e: TreeListExpandChangeEvent) => {
    setListState({
      ...listState,
      expanded: e.value
        ? listState.expanded.filter((id) => id !== e.dataItem._id)
        : [...listState.expanded, e.dataItem._id],
    });
  };

  const addParent = (
    dataTree: CompanyCategoryWithParent[],
    parent?: CompanyCategoryWithParent,
  ) => {
    for (const companyCategory of dataTree) {
      addParent(companyCategory.children, companyCategory);
      companyCategory.parent = parent;
    }
    return dataTree;
  };

  const removeParent = (dataTree: CompanyCategoryWithParent[]) => {
    for (const companyCategory of dataTree) {
      delete companyCategory.parent;
      removeParent(companyCategory.children);
    }
    return dataTree;
  };

  const addExpandField = (dataTree: CompanyCategory[]) => {
    const expanded = listState.expanded;
    return mapTree(dataTree, subItemsField, (item) =>
      extendDataItem(item, subItemsField, {
        [expandField]: expanded.includes(item._id),
      }),
    );
  };

  const move = (level: number[], down: boolean) => {
    setListState((state) => {
      const data = structuredClone(listState.data);
      if (level.length === 1) {
        const ele = data[level[0]];
        data.splice(level[0], 1);
        if (down) {
          data.splice(level[0] + 1, 0, ele);
        } else {
          data.splice(level[0] - 1, 0, ele);
        }
        return {
          ...state,
          data: data,
        };
      } else {
        let listToModify = data;
        for (let i = 0; i < level.length - 1; i++) {
          listToModify = listToModify[level[i]].children;
        }
        const ele = listToModify[level[level.length - 1]];
        listToModify.splice(level[level.length - 1], 1);
        if (down) {
          listToModify.splice(level[level.length - 1] + 1, 0, ele);
        } else {
          listToModify.splice(level[level.length - 1] - 1, 0, ele);
        }
        return {
          ...state,
          data: data,
        };
      }
    });
    setIsDirty(true);
  };

  return (
    <>
      <PageTitle title="Kategorien" />

      <form className="k-form k-form-md relative flex w-full flex-col rounded-md bg-white shadow lg:w-full">
        <Fieldset
          legend="Sortierung"
          description="Hier können die verfügbaren Kategorien aus BTS in eine gewünschte Reihenfolge gebracht werden. In dieser Reihenfolge tauchen die Kategorien auch auf der öffentlichen Seite auf."
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <TreeList
              className="w-full"
              onExpandChange={onExpandChange}
              expandField={expandField}
              subItemsField={subItemsField}
              data={addExpandField(listState.data)}
              columns={[
                {
                  field: 'name',
                  title: 'Name',
                  expandable: true,
                  width: '100%',
                },
                {
                  title: 'Sortierung',
                  cell: (props) => (
                    <td className={'flex gap-1'}>
                      <Button
                        fillMode={'outline'}
                        type={'button'}
                        themeColor={'secondary'}
                        disabled={props.level[props.level.length - 1] === 0}
                        onClick={() => move(props.level, false)}
                        title="Nach oben"
                      >
                        <HeroIcon name="ChevronUp" className="block h-5 w-5" />
                      </Button>
                      <Button
                        fillMode={'outline'}
                        type={'button'}
                        themeColor={'secondary'}
                        onClick={() => move(props.level, true)}
                        title="Nach unten"
                        disabled={
                          props?.dataItem?.parent
                            ? props.level[props.level.length - 1] ===
                              props.dataItem?.parent?.children?.length - 1
                            : props.level[props.level.length - 1] ===
                              listState.data.length - 1
                        }
                      >
                        <HeroIcon
                          name="ChevronDown"
                          className="block h-5 w-5"
                        />
                      </Button>
                    </td>
                  ),
                },
              ]}
            />
          </div>
        </Fieldset>
        <div className="k-form-buttons !mt-0 justify-end rounded-md bg-gray-50 !px-8 !py-3 !text-right">
          <Button
            type={'button'}
            themeColor={'primary'}
            className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
            disabled={!isDirty}
            onClick={onSubmit}
            title="Speichern"
          >
            <HeroIcon name="CheckCircle" className="block h-5 w-5" />
            <div>Speichern</div>
          </Button>
        </div>
      </form>
    </>
  );
};
