import { graphql } from '../gql/index.ts';
import { useMutation } from 'urql';
import { usePublicCompany } from './hooks/usePublicCompany.ts';
import { DropdownStateWrapper } from '../admin/form-components/DropdownStateWrapper.tsx';
import { PersonTitle, PublicUpdateUserDto } from '../gql/graphql.ts';
import { InputStateWrapper } from '../admin/form-components/InputStateWrapper.tsx';
import { useForm } from 'react-hook-form';
import { CheckboxStateWrapper } from '../admin/form-components/CheckboxStateWrapper.tsx';
import { Button } from '@progress/kendo-react-buttons';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import { PublicProfileSidebar } from './PublicProfileSidebar.tsx';
import { useUserInfo } from './hooks/useUserInfo.ts';
import { Loader } from '@progress/kendo-react-indicators';

const UpdateProfileMutation = graphql(/* GraphQL */ `
  mutation updateMyProfile($companyId: String!, $user: PublicUpdateUserDto!) {
    publicUpdateUser(companyId: $companyId, user: $user)
  }
`);

interface MyProfileDataProps {
  companyId: string;
}

interface ProfileDataForm {
  title: PersonTitle;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordRepeat: string;
  allowSevenDaysReminder: boolean;
  allowOneDayReminder: boolean;
}

export const MyProfileData: React.FC<MyProfileDataProps> = ({ companyId }) => {
  const [, updateProfile] = useMutation(UpdateProfileMutation);

  const [publicCompany] = usePublicCompany();
  const { userInfo, refetchUserInfo } = useUserInfo();

  const showSidebar =
    publicCompany?.functionalSettings?.searchActive ||
    Boolean(publicCompany?.sites?.length) ||
    Boolean(publicCompany?.categories?.length);

  const { control, formState, handleSubmit, watch, reset } =
    useForm<ProfileDataForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      values: {
        title:
          userInfo?.data?.companiesForSessionUser.user.title || PersonTitle.Mr,
        firstName: userInfo?.data?.companiesForSessionUser.user.firstName || '',
        lastName: userInfo?.data?.companiesForSessionUser.user.lastName || '',
        email: userInfo?.data?.companiesForSessionUser.user.email || '',
        allowOneDayReminder:
          userInfo?.data?.companiesForSessionUser.user.allowOneDayReminder ||
          false,
        allowSevenDaysReminder:
          userInfo?.data?.companiesForSessionUser.user.allowSevenDaysReminder ||
          false,
        password: '',
        passwordRepeat: '',
      },
    });

  const { isDirty, isSubmitting } = formState;

  return (
    <>
      <PublicProfileSidebar />
      <div
        className={`${showSidebar ? 'md:col-span-9 md:col-start-4' : 'md:col-span-12'} col-span-1 flex flex-col gap-4`}
      >
        <h2>Stammdaten</h2>
        <div className="relative flex flex-col rounded-md border border-gray-200 bg-white p-6 md:p-8">
          <form
            className="grid grid-cols-2 gap-4"
            onSubmit={handleSubmit((formData) => {
              const formDataWithoutPasswordRepeat = Object.fromEntries(
                Object.entries(formData).filter(
                  ([key]) => !['passwordRepeat'].includes(key),
                ),
              );
              return updateProfile({
                companyId,
                user: formDataWithoutPasswordRepeat as PublicUpdateUserDto,
              }).then(() => {
                refetchUserInfo();
                reset();
              });
            })}
          >
            <DropdownStateWrapper
              options={[
                { text: 'Herr', value: PersonTitle.Mr },
                { text: 'Frau', value: PersonTitle.Ms },
              ]}
              name={'title'}
              label={'Anrede'}
              className="!w-auto"
              wrapperClassName="col-span-1"
              required
              control={control}
            />
            <div className="col-span-1"></div>
            <InputStateWrapper
              name={'firstName'}
              label={'Vorname'}
              required
              className="col-span-1"
              rules={{ required: true }}
              control={control}
            />
            <InputStateWrapper
              name={'lastName'}
              label={'Nachname'}
              required
              className="col-span-1"
              rules={{ required: true }}
              control={control}
            />

            <InputStateWrapper
              name={'email'}
              label={'E-Mail'}
              wrapperClassName="col-span-2"
              rules={{ required: true }}
              control={control}
            />
            <InputStateWrapper
              type="password"
              name={'password'}
              label={'Passwort'}
              className="col-span-1"
              control={control}
            />
            <InputStateWrapper
              type="password"
              name={'passwordRepeat'}
              label={'Passwort Wiederholung'}
              className="col-span-1"
              control={control}
              rules={{
                validate: (val) => {
                  if (watch('password') != val) {
                    return 'Passwörter stimmen nicht überein';
                  }
                },
              }}
            />
            <CheckboxStateWrapper
              name={'allowOneDayReminder'}
              control={control}
              label={'Erinnerung 24h vor einem Seminar schicken'}
            />
            <CheckboxStateWrapper
              name={'allowSevenDaysReminder'}
              control={control}
              label={'Erinnerung 7 Tage vor einem Seminar schicken'}
            />

            <div className="pt-4">
              <Button
                disabled={!isDirty || isSubmitting}
                type={'submit'}
                themeColor={'primary'}
                className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
                title="Speichern"
              >
                {isSubmitting ? (
                  <Loader type={'pulsing'} className={'!text-white'} />
                ) : (
                  <HeroIcon name="CheckCircle" className="block h-5 w-5" />
                )}
                <div>Speichern</div>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
