import { ModulePrivileges } from '../gql/graphql';

export const getFormattedSeminarDate = (
  startDate: string,
  endDate: string,
  dateText?: string | null,
) => {
  if (dateText) {
    return dateText;
  }

  if (!startDate && !endDate) {
    return undefined;
  }

  const startDateObj = startDate ? new Date(startDate) : undefined;
  const endDateObj = endDate ? new Date(endDate) : undefined;
  const formattedStartDateString = `${startDateObj?.toLocaleDateString(
    'de-DE',
    {
      dateStyle: 'long',
    },
  )}`;
  const formattedStartTimeString = `${startDateObj?.toLocaleTimeString(
    'de-DE',
    {
      timeStyle: 'short',
    },
  )}`;
  const formattedEndDateString = `${endDateObj?.toLocaleDateString('de-DE', {
    dateStyle: 'long',
  })}`;
  const formattedEndTimeString = `${endDateObj?.toLocaleTimeString('de-DE', {
    timeStyle: 'short',
  })}`;
  if (
    formattedStartDateString === formattedEndDateString &&
    formattedStartDateString
  ) {
    return `${formattedStartDateString} (${formattedStartTimeString} - ${formattedEndTimeString} Uhr)`;
  }
  return `${startDate ? `${formattedStartDateString} (${formattedStartTimeString} Uhr)` : ''}${endDate ? ` - ${formattedEndDateString} (${formattedEndTimeString} Uhr)` : ''}`;
};

export const getFormattedDate = (date: string): string => {
  const dateObj = date ? new Date(date) : undefined;

  if (dateObj) {
    const formattedDateString = `${dateObj?.toLocaleDateString('de-DE', {
      dateStyle: 'long',
    })}`;
    const formattedTimeString = `${dateObj?.toLocaleTimeString('de-DE', {
      timeStyle: 'short',
    })}`;

    return `${formattedDateString} (${formattedTimeString} Uhr)`;
  }

  return '';
};

export const cloneAndRemoveTypename = <T>(obj: T): T => {
  return JSON.parse(
    JSON.stringify(obj, (k, v) => (k === '__typename' ? undefined : v)),
  );
};

export enum USER_ROLE {
  MEMBER = 'Teilnehmer',
  LECTURER = 'Dozent',
  ADMIN = 'Admin',
  BTS_LECTURER = 'Dozent (BTS)',
}

export const privilegesToRole = (
  hasAccount: boolean,
  privileges: string[] | null,
): USER_ROLE | null => {
  let userType = USER_ROLE.BTS_LECTURER;

  if (hasAccount && (privileges?.length || 0) > 0) {
    const adminPrivileges =
      privileges?.filter(
        (p) =>
          !(p == ModulePrivileges.Member || p == ModulePrivileges.Lecturer),
      ) || [];

    if (adminPrivileges.length > 0) {
      userType = USER_ROLE.ADMIN;
    } else {
      if (privileges?.includes(ModulePrivileges.Lecturer)) {
        userType = USER_ROLE.LECTURER;
      } else if (privileges?.includes(ModulePrivileges.Member)) {
        userType = USER_ROLE.MEMBER;
      }
    }
  }

  return userType;
};
