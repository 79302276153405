import { graphql } from '../gql/index.ts';
import { useQuery } from 'urql';
import { SeminarCard } from './SeminarCard.tsx';
import { usePublicCompany } from './hooks/usePublicCompany.ts';
import { PublicMenuSidebar } from './PublicMenuSidebar.tsx';

interface PublicSiteProps {
  companyId: string;
  slug: string;
}

const PublicSitesQuery = graphql(/* GraphQL */ `
  query getPublicSites($companyId: String!, $slug: String!) {
    publicCompanySite(companyId: $companyId, slug: $slug) {
      title
      slug
      draft
      isFooterSite
      externalUrl
      body
    }
  }
`);

export const PublicSite: React.FC<PublicSiteProps> = ({ companyId, slug }) => {
  const [publicSiteQueryResult] = useQuery({
    query: PublicSitesQuery,
    variables: {
      companyId,
      slug,
    },
  });
  const { data } = publicSiteQueryResult;
  const { publicCompanySite } = data || {};
  const [publicCompany] = usePublicCompany();

  const showSidebar =
    publicCompany?.functionalSettings.searchActive ||
    Boolean(publicCompany?.sites.length) ||
    Boolean(publicCompany?.categories.length);

  return (
    publicSiteQueryResult.data && (
      <>
        {showSidebar && <PublicMenuSidebar publicCompany={publicCompany} />}
        <div
          className={`${showSidebar ? 'md:col-span-9 md:col-start-4' : 'md:col-span-12'} col-span-1 flex flex-col gap-4`}
        >
          <h2>{publicCompanySite?.title}</h2>
          <SeminarCard description={publicCompanySite?.body} singleView />
        </div>
      </>
    )
  );
};
