import { HeroIcon } from '../shared/HeroIcon';

interface LecturerProfileImageProps {
  src?: string;
  onClick?: () => void;
  size?: 'large' | 'default';
}

export const LecturerProfileImage: React.FC<LecturerProfileImageProps> = ({
  src,
  onClick,
  size = 'default',
}) => {
  return (
    <div
      className={`relative shrink-0 overflow-hidden rounded-full ${onClick ? 'cursor-pointer' : ''} ${size === 'default' ? 'h-14 w-14' : ''} ${size === 'large' ? 'h-36 w-36' : ''}`}
      onClick={onClick}
    >
      {src ? (
        <img className="h-full w-full object-cover" src={'/api' + src}></img>
      ) : (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-300">
          <HeroIcon
            name={'UserCircle'}
            variant="solid"
            className={`text-white/80 ${size === 'default' ? 'h-12 w-12' : ''} ${size === 'large' ? 'h-32 w-32' : ''}`}
          />
        </div>
      )}
    </div>
  );
};
