import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { Input, InputProps } from './Input.tsx';

interface InputStateWrapperProps<T extends FieldValues>
  extends UseControllerProps<T>,
    Omit<InputProps, 'defaultValue' | 'name' | 'id'> {
  label?: string;
  disabled?: boolean;
  hint?: string;
  type?: string;
  placeholder?: string;
  wrapperClassName?: string;
}

export const InputStateWrapper = <T extends FieldValues>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  disabled,
  type,
  wrapperClassName,
  ...rest
}: InputStateWrapperProps<T>) => {
  const {
    field,
    fieldState: { error, isTouched },
  } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
    disabled,
  });

  const { value, ...fieldRest } = field;

  return (
    <Input
      {...rest}
      value={value ?? ''}
      {...fieldRest}
      id={field?.name}
      onChange={(e) => {
        if (type === 'number' && e?.target?.value) {
          field?.onChange(Number(e?.target?.value));
        } else {
          field?.onChange(e?.target?.value);
        }
      }}
      type={type}
      touched={isTouched}
      validationMessage={error?.message}
      required={Boolean(rules?.required)}
      wrapperClassName={wrapperClassName}
    />
  );
};
