import { useMutation, useQuery } from 'urql';
import { graphql } from '../../gql';
import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { PageTitle } from '../PageTitle.tsx';
import { Fieldset } from '../form-components/Fieldset.tsx';
import { HeroIcon } from '../../shared/HeroIcon.tsx';
import { Link } from 'wouter';
import { SignUpCustomField } from '../../gql/graphql.ts';
import { cloneAndRemoveTypename } from '../../public/util.ts';

interface SignUpFormPageProps {
  companyId: string;
}

export const SignUpFormElementsQuery = graphql(/* GraphQL */ `
  query companySignUpFormElements($companyId: String!) {
    companySignUpCustomFields(companyId: $companyId) {
      id
      type
      btsClass
      btsName
      name
      required
      showAfter
      showInMail
      systemField
      type
      validator
      visibleIfField
      visibleIfValue
      options {
        displayValue
        value
      }
    }
  }
`);

export const UpdateSignUpFormElementsMutation = graphql(/* GraphQL */ `
  mutation updateSignUpFormElements(
    $signUpFormElements: [SignUpCustomFieldInput!]!
    $companyId: String!
  ) {
    updateSignUpCustomFields(
      companyId: $companyId
      signUpCustomFields: $signUpFormElements
    )
  }
`);

export const SignUpFormPage: React.FC<SignUpFormPageProps> = ({
  companyId,
}) => {
  // TODO: Error handling
  const [{ data, fetching }, reloadSeminars] = useQuery({
    query: SignUpFormElementsQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });

  const [, updatePublicSeminarPageFields] = useMutation(
    UpdateSignUpFormElementsMutation,
  );

  const { control, formState, handleSubmit, reset } = useForm<{
    elements: SignUpCustomField[];
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (!fetching && data?.companySignUpCustomFields) {
      reset({
        elements: cloneAndRemoveTypename(data?.companySignUpCustomFields),
      });
    }
  }, [fetching]);

  const { fields, remove, move } = useFieldArray({
    control,
    name: 'elements',
    keyName: 'notUsed',
  });

  const { isValid, isDirty } = formState;

  const onFormSubmit = (value: { elements: SignUpCustomField[] }) => {
    updatePublicSeminarPageFields({
      companyId,
      signUpFormElements: value.elements,
    }).then(() => {
      reloadSeminars();
    });
  };

  const pageTitle = 'Anmeldeoptionen';

  return (
    <>
      <PageTitle title={pageTitle} />

      <div className={'mb-4 flex justify-end'}>
        <Link
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          href={'/sign-up-form-elements/new'}
        >
          <HeroIcon name="Plus" className="h-5 w-5" />
          Neues Feld
        </Link>
      </div>
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="k-form k-form-md relative flex w-full flex-col rounded-md bg-white shadow lg:w-full"
      >
        <Fieldset
          legend={`Formularfelder`}
          description="Hier kann der Aufbau und der Inhalt des Anmeldeformulars für Seminare angepasst werden."
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <Grid
              className="h-auto [&_.k-grid-norecords-template]:border-0"
              data={fields}
            >
              <GridNoRecords>Keine Formularfelder festgelegt</GridNoRecords>
              <GridColumn
                title="Pos."
                width={'50px'}
                cell={(props) => <td>{props.dataIndex + 1}.</td>}
              />
              <GridColumn
                title="Name"
                cell={(props) => <td>{fields[props.dataIndex].name}</td>}
              />
              <GridColumn
                title="BTS-Name"
                cell={(props) => <td>{fields[props.dataIndex].btsName}</td>}
              />
              <GridColumn
                title="Aktionen"
                width="220px"
                cell={(props) => (
                  <td className={'k-table-td'}>
                    <div className="k-button-link-secondary flex gap-1">
                      <Button
                        fillMode={'outline'}
                        type={'button'}
                        themeColor={'secondary'}
                        disabled={props.dataIndex === 0}
                        title="Feld nach oben verschieben"
                        onClick={() =>
                          move(props.dataIndex, props.dataIndex - 1)
                        }
                      >
                        <HeroIcon name="ChevronUp" className="block h-5 w-5" />
                      </Button>
                      <Button
                        fillMode={'outline'}
                        type={'button'}
                        themeColor={'secondary'}
                        title="Feld nach unten verschieben"
                        onClick={() =>
                          move(props.dataIndex, props.dataIndex + 1)
                        }
                        disabled={props.dataIndex === fields.length - 1}
                      >
                        <HeroIcon
                          name="ChevronDown"
                          className="block h-5 w-5"
                        />
                      </Button>
                      <Link
                        href={`/sign-up-form-elements/edit/${fields[props.dataIndex].id}`}
                        className="k-button k-button-md k-rounded-md k-button-outline k-button-outline-secondary"
                        title="Feld bearbeiten"
                      >
                        <HeroIcon name="Pencil" className="block h-5 w-5" />
                      </Link>
                      <Button
                        fillMode={'outline'}
                        type={'button'}
                        themeColor={'secondary'}
                        onClick={() => remove(props.dataIndex)}
                        title="Feld löschen"
                      >
                        <HeroIcon name="Trash" className="block h-5 w-5" />
                      </Button>
                    </div>
                  </td>
                )}
              />
            </Grid>
          </div>
        </Fieldset>
        <div className="k-form-buttons !mt-0 justify-end rounded-md bg-gray-50 !px-8 !py-3 !text-right">
          <Button
            type={'submit'}
            themeColor={'primary'}
            className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
            disabled={!isValid || !isDirty}
            title="Speichern"
          >
            <HeroIcon name="CheckCircle" className="block h-5 w-5" />
            <div>Speichern</div>
          </Button>
        </div>
      </form>
    </>
  );
};
