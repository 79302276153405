import { PageTitle } from './PageTitle.tsx';
import { Fieldset } from './form-components/Fieldset.tsx';
import { Button } from '@progress/kendo-react-buttons';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import React, { useEffect, useState } from 'react';
import { graphql } from '../gql';
import { useMutation, useQuery } from 'urql';

interface CompanyBannerPageProps {
  companyId: string;
}

const CompanyBannerQuery = graphql(/* GraphQL */ `
  query companyBanner($companyId: String!) {
    company(companyId: $companyId) {
      visualSettings {
        pathToBanner
      }
    }
  }
`);
const RemoveBannerMutation = graphql(/* GraphQL */ `
  mutation removeBanner($companyId: String!) {
    removeBanner(companyId: $companyId) {
      id
    }
  }
`);

export const CompanyBannerPage: React.FC<CompanyBannerPageProps> = ({
  companyId,
}) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [preview, setPreview] = useState<string>();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [initialStateWasEmpty, setInitialStateWasEmpty] =
    useState<boolean>(false);

  // TODO: Error handling
  const [{ data, fetching }, reloadBanner] = useQuery({
    query: CompanyBannerQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });

  const [, removeBanner] = useMutation(RemoveBannerMutation);

  useEffect(() => {
    if (!fetching && data?.company?.visualSettings?.pathToBanner && !preview) {
      setPreview(`/api${data?.company.visualSettings.pathToBanner}`);
    }
    if (!fetching && data?.company?.visualSettings?.pathToBanner === null) {
      setInitialStateWasEmpty(true);
    }
  }, [fetching]);

  useEffect(() => {
    if (!file) {
      return;
    }
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (file) {
      const formData = new FormData();
      formData.append('banner', file);
      formData.append('companyId', companyId);

      await fetch('/api/banner', {
        method: 'PUT',
        body: formData,
      });
    } else {
      await removeBanner({
        companyId,
      });
    }
    reloadBanner();

    setIsDirty(false);
  };

  return (
    <>
      <PageTitle title="Banner" />
      <form
        onSubmit={onFormSubmit}
        className="k-form k-form-md relative flex w-full flex-col rounded-md bg-white shadow lg:w-full"
      >
        <Fieldset
          legend="Bannergrafik"
          description="Der Banner wird auf der öffentlichen Seite stets im Header-Bereich angezeigt. Die ideale Breite dieser Grafik ist 1280 Pixel."
        >
          <div className="grid grid-cols-2 gap-x-8 gap-y-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <div className={'flex flex-col'}>
              {!preview && (
                <input
                  type={'file'}
                  id="banner"
                  name="banner"
                  accept={'image/png, image/jpeg'}
                  className="block w-96 cursor-pointer rounded-md border border-gray-200 p-2 file:h-8 file:rounded-md file:border-none file:bg-gray-300 file:p-2"
                  onChange={(e) => {
                    e.preventDefault();
                    setIsDirty(true);
                    setFile(
                      e.currentTarget?.files?.[0]
                        ? e.currentTarget.files[0]
                        : undefined,
                    );
                  }}
                />
              )}
              {preview && (
                <img
                  src={preview}
                  alt={'Bannergrafik Vorschau'}
                  className="border"
                />
              )}
            </div>
          </div>
        </Fieldset>

        <div className="k-form-buttons !mt-0 justify-end rounded-md bg-gray-50 !px-8 !py-3 !text-right">
          <Button
            themeColor={'primary'}
            className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
            fillMode="outline"
            disabled={!preview}
            onClick={(e) => {
              e.preventDefault();
              setFile(undefined);
              setPreview(undefined);
              setIsDirty(!initialStateWasEmpty);
            }}
            title="Zurücksetzen"
          >
            <HeroIcon name="Trash" className="block h-5 w-5" />
            <div>Zurücksetzen</div>
          </Button>
          <Button
            type={'submit'}
            themeColor={'primary'}
            className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
            disabled={!isDirty}
            title="Speichern"
          >
            <HeroIcon name="CheckCircle" className="block h-5 w-5" />
            <div>Speichern</div>
          </Button>
        </div>
      </form>
    </>
  );
};
