import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label, Error, Hint } from '@progress/kendo-react-labels';
import {
  DateTimePicker,
  DateTimePickerProps,
} from '@progress/kendo-react-dateinputs';

interface DateTimePickerStateWrapperProps<T extends FieldValues>
  extends UseControllerProps<T>,
    Omit<DateTimePickerProps, 'defaultValue' | 'name'> {
  label?: string;
  disabled?: boolean;
  hint?: string;
  type?: string;
  placeholder?: string;
  optional?: boolean;
}

export const DateTimePickerStateWrapper = <T extends FieldValues>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  disabled,
  hint,
  optional,
  label,
  ...rest
}: DateTimePickerStateWrapperProps<T>) => {
  const {
    field,
    fieldState: { error, isTouched },
  } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
    disabled,
  });

  const id = field.name;
  const showValidationMessage = Boolean(isTouched && error?.message);
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';

  return (
    <FieldWrapper className="self-start">
      <Label
        editorId={id}
        editorValid={!showValidationMessage}
        editorDisabled={disabled}
        optional={optional}
        className="k-form-label"
      >
        {label}
      </Label>
      <div className={'k-form-field-wrap'}>
        <DateTimePicker
          format={'dd.MM.yyyy HH:mm'}
          {...rest}
          {...field}
          id={id}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{error?.message}</Error>}
      </div>
    </FieldWrapper>
  );
};
