import { InputPrefix, TextBox } from '@progress/kendo-react-inputs';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import { CategorySiteTree } from './CategorySiteTree.tsx';
import { PublicCompanyData } from './contexts/PublicCompanyProvider.tsx';
import { Link, useLocation } from 'wouter';
import { useEffect, useState } from 'react';
import { useSearchParameter } from './hooks/useSearchParameter.ts';

interface PublicMenuSidebarProps {
  publicCompany?: PublicCompanyData;
  hideCategories?: boolean;
  hideSites?: boolean;
  hideSearch?: boolean;
}

export const PublicMenuSidebar: React.FC<PublicMenuSidebarProps> = ({
  publicCompany,
  hideCategories,
  hideSites,
  hideSearch,
}) => {
  const searchParameterFromUrl = useSearchParameter();
  const [searchInputValue, setSearchInputValue] = useState<string>(
    searchParameterFromUrl || '',
  );
  const [, setLocation] = useLocation();
  const searchTargetPath = searchInputValue
    ? '/?search=' + encodeURIComponent(searchInputValue)
    : '/';

  useEffect(() => {
    if (!searchParameterFromUrl) {
      setSearchInputValue('');
    }
  }, [searchParameterFromUrl]);

  return (
    <aside className="order-1 col-span-1 flex flex-col gap-4 md:order-none md:col-span-3">
      {(publicCompany?.functionalSettings.searchActive ||
        (hideSearch !== undefined && !hideSearch)) && (
        <>
          <h2>Suche</h2>
          <TextBox
            placeholder="Suchbegriff"
            className="!border-gray-200 [&_.k-input-inner]:outline-none"
            value={searchInputValue}
            onChange={(e) => {
              setSearchInputValue(String(e.value));
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setLocation(searchTargetPath);
              }
            }}
            prefix={() => (
              <InputPrefix>
                <Link
                  className={
                    !searchInputValue
                      ? 'pointer-events-none cursor-default'
                      : ''
                  }
                  href={searchTargetPath}
                >
                  <HeroIcon name="MagnifyingGlass" className="ml-2 h-5 w-5" />
                </Link>
              </InputPrefix>
            )}
            size={'large'}
          />
        </>
      )}

      {publicCompany &&
        (Boolean(publicCompany?.sites.length) ||
          Boolean(publicCompany?.categories.length)) && (
          <>
            <h2>Seminarangebot</h2>
            <CategorySiteTree
              company={publicCompany}
              hideCategories={hideCategories}
              hideSites={hideSites}
            />
          </>
        )}
    </aside>
  );
};
