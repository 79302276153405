interface RedirectToLoginAndBackProps {
  target?: string;
}

export const RedirectToLoginAndBack: React.FC<RedirectToLoginAndBackProps> = ({
  target,
}) => {
  const href = window.location.href;
  window.location.href = `/api/auth/login?successRef=${encodeURIComponent(href + (href.endsWith('/') ? '' : '/') + (target || ''))}`;

  return <></>;
};
