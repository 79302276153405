import { useMutation, useQuery } from 'urql';
import { graphql } from '../../gql';
import { MailTemplate } from '../../gql/graphql.ts';
import { MailTemplateEditor } from './MailTemplateEditor.tsx';

interface MailSettingsPageProps {
  companyId: string;
}

const MailSettingsParticipantTemplateQuery = graphql(/* GraphQL */ `
  query mailSevenDays($companyId: String!) {
    company(companyId: $companyId) {
      mailSettings {
        mailTemplateReminderSevenDays {
          subject
          template
        }
      }
    }
  }
`);

const UpdateParticipantTemplateQuery = graphql(/* GraphQL */ `
  mutation updateTemplateSevenDays(
    $companyId: String!
    $company: UpdateCompanyDto!
  ) {
    updateCompany(companyId: $companyId, company: $company) {
      mailSettings {
        mailTemplateReminderSevenDays {
          subject
          template
        }
      }
    }
  }
`);

export const ReminderSevenDaysTemplateEditor: React.FC<
  MailSettingsPageProps
> = ({ companyId }) => {
  // TODO: Error handling
  const [{ data }, reExecuteQuery] = useQuery({
    query: MailSettingsParticipantTemplateQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });

  const [, updateCompany] = useMutation(UpdateParticipantTemplateQuery);

  const onFormSubmit = (values: MailTemplate) => {
    updateCompany({
      companyId,
      company: { mailSettings: { mailTemplateReminderSevenDays: values } },
    }).then(() => {
      // TODO: Check for errors and indicate success/failure to UI
      reExecuteQuery();
    });
  };

  return (
    <>
      {data?.company.mailSettings.mailTemplateReminderSevenDays && (
        <MailTemplateEditor
          companyId={companyId}
          template={data.company.mailSettings.mailTemplateReminderSevenDays}
          onSubmit={onFormSubmit}
        />
      )}
    </>
  );
};
