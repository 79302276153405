import BtsLogo from '../assets/bts_logo.svg?react';
import { TreeView } from '@progress/kendo-react-treeview';
import { Link, useLocation } from 'wouter';
import { Helmet } from 'react-helmet-async';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { ButtonGroup } from '@progress/kendo-react-buttons';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import { AdminMenuItem } from './AdminMenuItem.tsx';
import { useUserCompanies } from './hooks/useUserCompanies.ts';
import { expandMenuItem } from './menu.ts';
import { loadMessages } from '@progress/kendo-react-intl';
import deMessages from './translation/de-DE.json';

loadMessages(deMessages, 'de-DE');

interface IAdminLayoutProps {
  children?: React.ReactNode;
  companyId: string;
}

export const AdminLayout: React.FC<IAdminLayoutProps> = ({
  children,
  companyId,
}) => {
  const [location] = useLocation();
  const { menu, companies, setMenu } = useUserCompanies();

  return companies ? (
    <div className="grid h-full grid-cols-[300px_1fr] grid-rows-[70px_1fr]">
      <Helmet>
        <title>Admin Dashboard - {import.meta.env.VITE_APP_TITLE}</title>
      </Helmet>
      <div className="z-10 col-span-2 flex items-center justify-between border-b bg-white p-4 shadow-md">
        <Link to="/" className="h-full w-auto">
          <BtsLogo className="h-full w-auto text-coral-400" />
        </Link>
        <div className="flex items-center gap-4">
          {companies && <span>Hallo, {companies?.user?.firstName}</span>}
          <ButtonGroup>
            {companies?.companiesForUser?.length && (
              <DropDownList
                className={`!w-64 !rounded-r-none !border-r-0 !border-[#6c757d] !text-[#6c757d] hover:!bg-[#6c757d] hover:!text-white ${
                  companies?.companiesForUser?.length < 2
                    ? '[&_button]:invisible'
                    : ''
                }`}
                textField="name"
                dataItemKey="id"
                fillMode={'outline'}
                data={companies?.companiesForUser}
                disabled={companies?.companiesForUser?.length < 2}
                value={companies?.companiesForUser?.find(
                  (company) => company.id === companyId,
                )}
                onChange={(e) => {
                  const newCompanyId = e.target.value.id;

                  const newCompany = companies.companiesForUser.find(
                    (company) => company.id === newCompanyId,
                  );

                  if (newCompany) {
                    const newTarget = `${newCompany.domain.startsWith('http') ? '' : 'https://'}${newCompany.domain}/admin${location}`;
                    window.location.href = newTarget;
                  }
                }}
              />
            )}
            <a
              href={`/api/auth/logout`}
              className="k-button k-button-md k-button-outline k-button-outline-secondary k-rounded-md text-red"
            >
              <HeroIcon
                name={'ArrowLeftStartOnRectangle'}
                className="inline-block h-5 w-5"
              />
              Abmelden
            </a>
          </ButtonGroup>
        </div>
      </div>
      <aside className="h-full border-r bg-zinc-100 p-4">
        <TreeView
          size={'large'}
          // className="[&_.k-treeview-toggle]:text-coral-400"
          expandIcons={true}
          data={menu}
          className="[&_.k-disabled]:!opacity-35 [&_.k-selected]:!bg-white [&_.k-selected_a]:!font-semibold"
          onExpandChange={(e) => {
            const newMenu = expandMenuItem(e, menu);
            setMenu(newMenu);
          }}
          item={AdminMenuItem}
        />
      </aside>
      {/* Main Content */}
      <div className="h-full bg-zinc-100 px-8 py-4">{children}</div>
    </div>
  ) : null;
};
