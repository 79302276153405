import React, { useRef, useState } from 'react';
import { graphql } from '../gql';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import {
  DropDownList,
  DropDownListFilterChangeEvent,
} from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {
  CompositeFilterDescriptor,
  filterBy,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { Loader } from '@progress/kendo-react-indicators';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import { useMutation } from 'urql';
import { PublicSeminarFile, PublicSignUpDto } from '../gql/graphql.ts';

const toHumanFileSize = (size: number) => {
  const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    +(size / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  );
};

const RemoveFileMutation = graphql(/* GraphQL */ `
  mutation removeFile(
    $companyId: String!
    $seminarId: String!
    $blobId: String!
  ) {
    removeFile(companyId: $companyId, seminarId: $seminarId, blobId: $blobId)
  }
`);

interface PublicSeminarFilesTabProps {
  files?: PublicSeminarFile[];
  showUploadSection: boolean;
  companyId: string;
  seminarId: string;
  refreshData: () => void;
  participants: Array<Partial<PublicSignUpDto>>;
  showSpecificParticipantFilesOption?: boolean;
  showAllParticipantFilesOption?: boolean;
  showAllLecturerFilesOption?: boolean;
}

export const PublicSeminarFilesTab: React.FC<PublicSeminarFilesTabProps> = ({
  files,
  showUploadSection,
  companyId,
  seminarId,
  refreshData,
  participants,
  showSpecificParticipantFilesOption,
  showAllParticipantFilesOption,
  showAllLecturerFilesOption,
}) => {
  const [, removeFile] = useMutation(RemoveFileMutation);

  interface DropdownItem {
    text: string;
    value: string;
  }
  const accessLevelMapping = {
    ...(showAllLecturerFilesOption
      ? {
          LECTURER_AND_COMPANY_EMPLOYEE: 'Dozenten, Mitarbeiter',
        }
      : {}),
    ...(showAllParticipantFilesOption
      ? {
          PARTICIPANTS_AND_LECTURER_AND_COMPANY_EMPLOYEE:
            'Teilnehmer, Dozenten, Mitarbeiter',
        }
      : {}),
    ...(showSpecificParticipantFilesOption
      ? {
          SPECIFIC_PARTICIPANT_AND_COMPANY_EMPLOYEE: 'Individueller Teilnehmer',
        }
      : {}),
  };

  const levelsDropdownData: DropdownItem[] = Object.entries(
    accessLevelMapping,
  ).map(([key, value]) => ({
    text: value,
    value: key,
  }));

  const participantsDropdownData = participants
    .filter((participant) => participant.linkedToAccount)
    .map((participant) => ({
      name: participant.firstName + ' ' + participant.lastName,
      id: participant.id,
    }));

  const filterData = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
    const data = participantsDropdownData?.slice() || [];
    return filterBy(data, filter);
  };

  const [file, setFile] = useState<File>();
  const [level, setLevel] = useState<DropdownItem>(levelsDropdownData[0]);
  const [signUpId, setSignUpId] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [
    filteredParticipantsDropdownData,
    setFilteredParticipantsDropdownData,
  ] = useState(participantsDropdownData);
  const [deleteConfirmationBlobId, setDeleteConfirmationBlobId] = useState<
    string | null
  >();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const deleteFile = (blobId: string) => {
    return removeFile({
      companyId,
      seminarId,
      blobId,
    }).then(() => {
      refreshData();
    });
  };

  return (
    <>
      {deleteConfirmationBlobId && (
        <Dialog
          autoFocus={true}
          width={450}
          title={'Sind sie sicher?'}
          onClose={() => {
            setDeleteConfirmationBlobId(null);
          }}
        >
          <p className="m-6 text-center">
            Möchten Sie die Datei{' '}
            <strong>
              {
                files?.find((file) => file.blobId === deleteConfirmationBlobId)
                  ?.name
              }
            </strong>{' '}
            wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.
          </p>
          <DialogActionsBar>
            <Button
              themeColor={'primary'}
              disabled={isLoading}
              type="button"
              onClick={() => {
                setIsLoading(true);
                deleteFile(deleteConfirmationBlobId).then(() => {
                  setDeleteConfirmationBlobId(null);
                  setIsLoading(false);
                });
              }}
            >
              {isLoading ? (
                <Loader type={'pulsing'} className={'!text-white'} />
              ) : (
                'Ja, Datei löschen'
              )}
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      <Grid
        className="file-table h-auto !border-0 [&_.k-grid-norecords-template]:border-0"
        data={files}
        scrollable="none"
      >
        <GridNoRecords>Keine Dateien gefunden</GridNoRecords>
        <GridColumn
          title="Dateiname"
          cells={{
            data: (props) => (
              <td {...props.tdProps}>
                <a
                  target="_blank"
                  className="hover:!underline"
                  title="In neuem Tab öffnen"
                  href={'/api' + props.dataItem.pathToFile}
                >
                  {props.dataItem.name}
                </a>
              </td>
            ),
          }}
        />
        <GridColumn
          field="accessPermissionLevel"
          title="Zugriffslevel"
          cells={{
            data: (props) => {
              return (
                <td {...props.tdProps}>
                  {props.dataItem.specificSignUpId
                    ? participantsDropdownData?.find(
                        (participant) =>
                          participant.id === props.dataItem.specificSignUpId,
                      )?.name || 'Für mich'
                    : accessLevelMapping[
                        props.dataItem
                          .accessPermissionLevel as keyof typeof accessLevelMapping
                      ] || 'Für alle'}
                </td>
              );
            },
          }}
        />
        <GridColumn
          title="Uploaddatum"
          cells={{
            data: (props) => (
              <td {...props.tdProps}>
                {new Date(props.dataItem.uploadedAt).toLocaleString('de-DE')}
              </td>
            ),
          }}
        />
        <GridColumn
          title="Größe"
          cells={{
            data: (props) => (
              <td {...props.tdProps}>{toHumanFileSize(props.dataItem.size)}</td>
            ),
          }}
        />
        <GridColumn
          title="Aktionen"
          cells={{
            data: (props) => (
              <td
                {...props.tdProps}
                className={'k-table-td k-button-link-secondary'}
              >
                <div className="flex h-full space-x-2">
                  <a
                    href={'/api' + props.dataItem.pathToFile}
                    className="!text-[#6c757d] hover:!text-black"
                    title="Datei herunterladen"
                    target="_blank"
                    download=""
                  >
                    <HeroIcon
                      name="DocumentArrowDown"
                      className="block h-5 w-5"
                    />
                  </a>
                  {showUploadSection && (
                    <button
                      className="!text-[#6c757d] hover:!text-black"
                      title="Datei löschen"
                      disabled={isLoading}
                      onClick={() => {
                        setDeleteConfirmationBlobId(props.dataItem.blobId);
                      }}
                    >
                      <HeroIcon name="Trash" className="block h-5 w-5" />
                    </button>
                  )}
                </div>
              </td>
            ),
          }}
        />
      </Grid>

      {showUploadSection && (
        <div className="rounded-b-md border-t-2 px-2 py-4">
          <h4 className="mb-2 flex items-center gap-2">
            <HeroIcon className="inline-flex h-5 w-5" name="DocumentArrowUp" />
            Neue Datei hochladen
          </h4>
          <form
            className="grid grid-cols-[38fr_26fr_28fr_8fr] gap-2"
            onSubmit={(e) => {
              e.preventDefault();

              if (file) {
                const formData = new FormData();

                formData.append('file', file);
                formData.append('companyId', companyId);
                formData.append('seminarId', seminarId);
                if (signUpId) {
                  formData.append('specificSignUpId', signUpId);
                }
                formData.append(
                  'seminarFileAccessPermissionLevel',
                  level.value,
                );
                setIsLoading(true);
                fetch('/api/seminar/storage/', {
                  method: 'POST',
                  body: formData,
                }).then(() => {
                  setIsLoading(false);
                  refreshData();
                  setFile(undefined);
                  if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                  }
                  setLevel(levelsDropdownData[0]);
                  setSignUpId(undefined);
                });
              }
            }}
          >
            <input
              ref={fileInputRef}
              type={'file'}
              name={'fileupload'}
              id={'fileupload'}
              className="block h-9 w-full cursor-pointer rounded-md border border-[#e4e7eb] bg-white text-sm file:h-full file:border-none file:bg-[#e4e7eb] file:px-2 file:hover:bg-gray-400"
              onChange={(e) => {
                e.preventDefault();

                if (e.target.files?.[0]) {
                  setFile(e.target.files?.[0]);
                }
              }}
            />
            <DropDownList
              textField="text"
              dataItemKey="value"
              fillMode={'solid'}
              size="small"
              data={Object.entries(accessLevelMapping).map(([key, value]) => ({
                text: value,
                value: key,
              }))}
              value={level}
              onChange={(e) => {
                setLevel(e.target.value);
              }}
            />
            <DropDownList
              textField="name"
              dataItemKey="id"
              filterable={true}
              onFilterChange={(event: DropDownListFilterChangeEvent) => {
                setFilteredParticipantsDropdownData(filterData(event.filter));
              }}
              fillMode={'solid'}
              defaultValue={{ name: 'Teilnehmer auswählen' }}
              disabled={
                !(level.value === 'SPECIFIC_PARTICIPANT_AND_COMPANY_EMPLOYEE')
              }
              size="small"
              data={filteredParticipantsDropdownData}
              value={
                participantsDropdownData?.find(
                  (participant) => participant.id === signUpId,
                ) || { name: 'Teilnehmer auswählen' }
              }
              onChange={(e) => {
                setSignUpId(e.target.value.id);
              }}
            />
            <button
              disabled={
                !file ||
                isLoading ||
                (level.value === 'SPECIFIC_PARTICIPANT_AND_COMPANY_EMPLOYEE' &&
                  signUpId === undefined)
              }
              type="submit"
              className="k-button k-button-md k-button-solid k-button-solid-primary k-rounded-md"
            >
              {isLoading ? (
                <Loader type={'pulsing'} className={'!text-white'} />
              ) : (
                'Upload'
              )}
            </button>
          </form>
        </div>
      )}
    </>
  );
};
