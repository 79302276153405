import React from 'react';
import { DropdownStateWrapper } from '../admin/form-components/DropdownStateWrapper.tsx';
import { InputStateWrapper as HookedInput } from '../admin/form-components/InputStateWrapper.tsx';
import { useForm } from 'react-hook-form';
import { Button } from '@progress/kendo-react-buttons';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import { RegisterUserDto } from '../gql/graphql.ts';
import { graphql } from '../gql';
import { useMutation } from 'urql';
import { PageTitle } from '../admin/PageTitle.tsx';
import { Fieldset } from '../admin/form-components/Fieldset.tsx';
import { Loader } from '@progress/kendo-react-indicators';

interface Props {
  companyId: string;
}

const RegisterUserMutation = graphql(/* GraphQL */ `
  mutation publicRegisterUser($user: RegisterUserDto!) {
    publicRegisterUser(user: $user) {
      id
    }
  }
`);

export const PublicRegistration: React.FC<Props> = ({ companyId }) => {
  const [, addUser] = useMutation(RegisterUserMutation);

  const { control, formState, handleSubmit, getValues } = useForm<
    RegisterUserDto & { confirmPassword: string }
  >({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onFormSubmit = (
    value: RegisterUserDto & { confirmPassword: string },
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { confirmPassword, ...restUser } = value;
    return addUser({
      user: { ...restUser, companyId },
    }).then(() => {
      window.location.href = `/api/auth/login?successRef=${window.location.origin}`;
    });
  };

  const { isValid, isDirty, isSubmitting } = formState;

  return (
    <>
      <div className="col-span-6 col-start-4">
        <PageTitle title="Registrierung" />
        <form
          onSubmit={handleSubmit(onFormSubmit)}
          className="k-form k-form-md relative flex w-full flex-col rounded-md bg-white shadow lg:w-full"
          // className="k-form k-form-md relative flex w-full flex-col rounded-md bg-white shadow lg:w-full"
        >
          <Fieldset>
            <DropdownStateWrapper
              options={[
                { text: 'Herr', value: 'MR' },
                { text: 'Frau', value: 'MS' },
              ]}
              name={'title'}
              label={'Anrede'}
              control={control}
              className="!w-auto"
              wrapperClassName="col-span-6 !mt-0"
            />
            <div className="col-span-6" />
            <HookedInput
              name={'firstName'}
              label={'Vorname'}
              placeholder="Lisa"
              control={control}
              wrapperClassName="col-span-6"
            />
            <HookedInput
              name={'lastName'}
              label={'Nachname'}
              placeholder="Musterfrau"
              control={control}
              wrapperClassName="col-span-6"
            />
            <HookedInput
              name={'email'}
              label={'E-Mail'}
              placeholder="test@example.com"
              rules={{ required: true }}
              required={true}
              control={control}
              wrapperClassName="col-span-12"
            />
            <HookedInput
              name={'password'}
              label={'Passwort'}
              type={'password'}
              rules={{ required: true }}
              required={true}
              control={control}
              wrapperClassName="col-span-6"
            />
            <HookedInput
              name={'confirmPassword'}
              label={'Passwort wiederholen'}
              type={'password'}
              wrapperClassName="col-span-6"
              rules={{
                required: true,
                validate: (value) => {
                  const { password } = getValues();
                  return password === value || 'Passwort stimmt nicht überein!';
                },
              }}
              required={true}
              control={control}
            />
          </Fieldset>

          <div className="k-form-buttons !mt-0 justify-end rounded-md bg-gray-50 !px-8 !py-3 !text-right">
            <Button
              type={'submit'}
              themeColor={'primary'}
              className="!border-bts-theme-primary !bg-bts-theme-primary !shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
              disabled={!isValid || !isDirty || isSubmitting}
              title="Registrieren"
            >
              {isSubmitting ? (
                <Loader type={'pulsing'} className={'!text-white'} />
              ) : (
                <HeroIcon name="CheckCircle" className="block h-5 w-5" />
              )}
              <div>Registrieren</div>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
