import { useMutation, useQuery } from 'urql';
import { graphql } from '../gql/index.ts';
import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  PublicSeminarPageField,
  PublicSeminarPageFieldName,
  PublicSeminarPageFieldType,
} from '../gql/graphql.ts';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { PageTitle } from './PageTitle.tsx';
import { Fieldset } from './form-components/Fieldset.tsx';
import { DropdownStateWrapper } from './form-components/DropdownStateWrapper.tsx';
import { CheckboxStateWrapper } from './form-components/CheckboxStateWrapper.tsx';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import { cloneAndRemoveTypename } from '../public/util.ts';

interface PublicSeminarPageFieldsProps {
  companyId: string;
}

const PublicSeminarPageFieldsQuery = graphql(/* GraphQL */ `
  query companyPublicSeminarPageFields($companyId: String!) {
    companyPublicSeminarPageFields(companyId: $companyId) {
      fieldName
      type
      markdown
    }
  }
`);

const PublicSeminarPageFieldsMutation = graphql(/* GraphQL */ `
  mutation updatePublicSeminarPageFields(
    $publicSeminarPageFields: [PublicSeminarPageFieldInput!]!
    $companyId: String!
  ) {
    updatePublicSeminarPageFields(
      companyId: $companyId
      publicSeminarPageFields: $publicSeminarPageFields
    )
  }
`);

export const PublicSeminarPageFields: React.FC<
  PublicSeminarPageFieldsProps
> = ({ companyId }) => {
  // TODO: Error handling
  const [{ data, fetching }, reloadSeminars] = useQuery({
    query: PublicSeminarPageFieldsQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });

  const [, updatePublicSeminarPageFields] = useMutation(
    PublicSeminarPageFieldsMutation,
  );

  const { control, formState, handleSubmit, reset } = useForm<{
    publicSeminarPageFields: PublicSeminarPageField[];
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      publicSeminarPageFields: data?.companyPublicSeminarPageFields,
    },
  });

  useEffect(() => {
    if (!fetching && data?.companyPublicSeminarPageFields) {
      const withoutTypename =
        data?.companyPublicSeminarPageFields &&
        cloneAndRemoveTypename(data?.companyPublicSeminarPageFields);
      reset({
        publicSeminarPageFields: withoutTypename,
      });
    }
  }, [fetching]);

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'publicSeminarPageFields',
  });

  const { isValid, isDirty } = formState;

  const onFormSubmit = (value: {
    publicSeminarPageFields: PublicSeminarPageField[];
  }) => {
    updatePublicSeminarPageFields({
      companyId,
      publicSeminarPageFields: value.publicSeminarPageFields,
    }).then(() => {
      reloadSeminars();
    });
  };

  const fieldNameToReadableName = (
    fieldName?: PublicSeminarPageFieldName | null,
  ) => {
    const mapping = {
      [PublicSeminarPageFieldName.Description_1]: 'Beschreibung 1',
      [PublicSeminarPageFieldName.Description_2]: 'Beschreibung 2',
      [PublicSeminarPageFieldName.Description_3]: 'Beschreibung 3',
      [PublicSeminarPageFieldName.Description_4]: 'Beschreibung 4',
      [PublicSeminarPageFieldName.Description_5]: 'Beschreibung 5',
      [PublicSeminarPageFieldName.FreeText_1]: 'Freier Text 1',
      [PublicSeminarPageFieldName.FreeText_2]: 'Freier Text 2',
      [PublicSeminarPageFieldName.FreeText_3]: 'Freier Text 3',
      [PublicSeminarPageFieldName.FreeText_4]: 'Freier Text 4',
      [PublicSeminarPageFieldName.FreeText_5]: 'Freier Text 5',
      [PublicSeminarPageFieldName.Title]: 'Titel',
      [PublicSeminarPageFieldName.Subtitle]: 'Untertitel',
      [PublicSeminarPageFieldName.LocationText]: 'Ort',
      [PublicSeminarPageFieldName.InfoNotice]: 'Hinweis',
    };
    return fieldName ? mapping[fieldName] : '';
  };

  const pageTitle = 'Inhaltsblöcke';

  return (
    <>
      <PageTitle title={pageTitle} />
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="k-form k-form-md relative flex w-full flex-col rounded-md bg-white shadow lg:w-full"
      >
        <Fieldset
          legend={`Inhaltsblöcke`}
          description="Hier kann der Aufbau und der Inhalt der öffentlichen Seminaransicht geordnet und angepasst werden."
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <Grid
              className="h-auto [&_.k-grid-norecords-template]:border-0"
              data={fields.map((field) => ({
                ...field,
                fieldName: fieldNameToReadableName(field.fieldName),
              }))}
            >
              <GridNoRecords>Keine Blöcke angezeigt</GridNoRecords>
              <GridColumn
                title="Pos."
                width={'50px'}
                cell={(props) => <td>{props.dataIndex + 1}.</td>}
              />
              <GridColumn field="fieldName" title="Blockbezeichnung" />
              <GridColumn
                field="type"
                title="Aussehen"
                width="160px"
                cell={(props) => {
                  return (
                    <td className={'k-table-td'}>
                      <DropdownStateWrapper
                        className="!w-32"
                        options={[
                          {
                            text: 'Normal',
                            value: PublicSeminarPageFieldType.Normal,
                          },
                          {
                            text: 'Info',
                            value: PublicSeminarPageFieldType.Info,
                          },
                          {
                            text: 'Warnung',
                            value: PublicSeminarPageFieldType.Warning,
                          },
                          {
                            text: 'Fehler',
                            value: PublicSeminarPageFieldType.Error,
                          },
                          {
                            text: 'Notiz',
                            value: PublicSeminarPageFieldType.Notice,
                          },
                          {
                            text: 'Überschrift',
                            value: PublicSeminarPageFieldType.Heading,
                          },
                        ]}
                        name={`publicSeminarPageFields.${props.dataIndex}.type`}
                        control={control}
                      />
                    </td>
                  );
                }}
              />
              <GridColumn
                title="Markdown"
                width="100px"
                cell={(props) => (
                  <td>
                    <CheckboxStateWrapper
                      name={`publicSeminarPageFields.${props.dataIndex}.markdown`}
                      control={control}
                    />
                  </td>
                )}
              />
              <GridColumn
                title="Aktionen"
                width="200px"
                cell={(props) => (
                  <td className={'k-table-td'}>
                    <div className="flex gap-1">
                      <Button
                        fillMode={'outline'}
                        type={'button'}
                        themeColor={'secondary'}
                        disabled={props.dataIndex === 0}
                        onClick={() =>
                          move(props.dataIndex, props.dataIndex - 1)
                        }
                        title="Block nach oben schieben"
                      >
                        <HeroIcon name="ChevronUp" className="block h-5 w-5" />
                      </Button>
                      <Button
                        fillMode={'outline'}
                        type={'button'}
                        themeColor={'secondary'}
                        onClick={() =>
                          move(props.dataIndex, props.dataIndex + 1)
                        }
                        disabled={props.dataIndex === fields.length - 1}
                        title="Block nach unten schieben"
                      >
                        <HeroIcon
                          name="ChevronDown"
                          className="block h-5 w-5"
                        />
                      </Button>
                      <Button
                        fillMode={'outline'}
                        type={'button'}
                        themeColor={'secondary'}
                        onClick={() => remove(props.dataIndex)}
                        title="Block löschen"
                      >
                        <HeroIcon name="Trash" className="block h-5 w-5" />
                      </Button>
                    </div>
                  </td>
                )}
              />
            </Grid>
            <h3 className="mt-4 text-base font-semibold">Verfügbare Blöcke</h3>
            <div className="flex flex-wrap gap-2">
              {Object.values(PublicSeminarPageFieldName)
                .filter((val) => !fields.find((f) => f.fieldName === val))
                .map((val) => (
                  <button
                    key={val}
                    className="flex items-center gap-x-0.5 rounded-md bg-gray-100 px-2 py-1 text-sm font-medium text-gray-600 hover:bg-gray-300"
                    type="button"
                    title="Block hinzufügen"
                    onClick={() =>
                      append({
                        fieldName: val,
                        markdown: false,
                        type: PublicSeminarPageFieldType.Normal,
                      })
                    }
                  >
                    <HeroIcon name="PlusCircle" className="mr-1 h-5 w-5" />
                    {fieldNameToReadableName(val)}
                  </button>
                ))}
            </div>
          </div>
        </Fieldset>

        <div className="k-form-buttons !mt-0 justify-end rounded-md bg-gray-50 !px-8 !py-3 !text-right">
          <Button
            type={'submit'}
            themeColor={'primary'}
            className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
            disabled={!isValid || !isDirty}
            title="Speichern"
          >
            <HeroIcon name="CheckCircle" className="block h-5 w-5" />
            <div>Speichern</div>
          </Button>
        </div>
      </form>
    </>
  );
};
