import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { Checkbox, CheckboxProps } from '@progress/kendo-react-inputs';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Error, Hint } from '@progress/kendo-react-labels';

interface CheckboxStateWrapperProps<T extends FieldValues>
  extends UseControllerProps<T>,
    Omit<CheckboxProps, 'defaultValue' | 'name'> {
  label?: string;
  disabled?: boolean;
  hint?: string;
  type?: string;
  placeholder?: string;
  inverted?: boolean;
  wrapperClassName?: string;
}

export const CheckboxStateWrapper = <T extends FieldValues>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  disabled,
  hint,
  inverted,
  wrapperClassName,
  ...rest
}: CheckboxStateWrapperProps<T>) => {
  const {
    field,
    fieldState: { error, isTouched },
  } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
    disabled,
  });

  const id = field.name;
  const showValidationMessage = Boolean(isTouched && error?.message);
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';

  const { onChange, value, ...restField } = field;
  return (
    <FieldWrapper className={wrapperClassName}>
      <div className={'k-form-field-wrap'}>
        <Checkbox
          {...rest}
          {...restField}
          id={id}
          valid={!showValidationMessage}
          validationMessage={error?.message}
          required={Boolean(rules?.required)}
          ariaDescribedBy={`${hintId} ${errorId}`}
          disabled={disabled}
          onChange={(e) => {
            inverted ? onChange(!e.value) : onChange(e.value);
          }}
          value={inverted ? !value : value}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{error?.message}</Error>}
      </div>
    </FieldWrapper>
  );
};
