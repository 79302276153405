import { Link, useLocation } from 'wouter';
import { useUserInfo } from './hooks/useUserInfo';
import { usePublicCompany } from './hooks/usePublicCompany';
import { ModulePrivileges } from '../gql/graphql';

interface PublicProfileSidebarProps {}

export const PublicProfileSidebar: React.FC<PublicProfileSidebarProps> = () => {
  const [location] = useLocation();
  const { userInfo } = useUserInfo();
  const [publicCompany] = usePublicCompany();

  const isLecturerForCompany = Boolean(
    userInfo?.data?.companiesForSessionUser?.companiesForUser
      .find((company) => company.id === publicCompany?.id)
      ?.privileges.includes(ModulePrivileges.Lecturer),
  );

  const items = [
    { title: 'Meine Seminare', href: '/profile/myseminars' },
    isLecturerForCompany && {
      title: 'Meine Dozententätigkeit',
      href: '/profile/mylecturerseminars',
    },
    isLecturerForCompany && {
      title: 'Dozentenprofil',
      href: '/profile/lecturer',
    },
    { title: 'Stammdaten', href: '/profile/data' },
  ].filter(Boolean) as Array<{ title: string; href: string }>;

  return (
    <aside className="col-span-1 flex flex-col gap-4 md:col-span-3">
      <h2>Profil</h2>
      <ul className="flex flex-col divide-y rounded border border-gray-200 bg-white">
        {items.map((item, i) => {
          const isActive = location === item.href;
          return (
            <li
              key={item.href}
              className={`text-black ${i == 0 ? 'rounded-t' : ''} ${i == items.length - 1 ? 'rounded-b' : ''} ${isActive ? 'bg-bts-theme-primary' : 'hover:bg-gray-200'}`}
            >
              <Link
                href={item.href}
                className={
                  isActive
                    ? 'bg-bts-theme-primary text-white'
                    : '' + ' h-full w-full'
                }
              >
                <span className="flex h-full w-full items-center px-6 py-4">
                  {item.title}
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};
