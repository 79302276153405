import { HeroIcon } from '../shared/HeroIcon.tsx';

interface ErrorPageProps {
  text: string;
  icon?: string;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
  text,
  icon = 'NoSymbol',
}) => {
  return (
    <div className="col-span-1 flex flex-col items-center gap-8 md:col-span-9">
      {icon && (
        <HeroIcon
          className="h-32 w-32 text-bts-theme-text-lighter"
          name={icon}
        />
      )}
      <h2 className="text-bts-theme-text-lighter">{text}</h2>
    </div>
  );
};
