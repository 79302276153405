import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import {
  DropDownList,
  DropDownListProps,
} from '@progress/kendo-react-dropdowns';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label, Error, Hint } from '@progress/kendo-react-labels';

interface DropdownStateWrapperProps<T extends FieldValues>
  extends UseControllerProps<T>,
    Omit<DropDownListProps, 'defaultValue' | 'name'> {
  label?: string;
  disabled?: boolean;
  hint?: string;
  type?: string;
  placeholder?: string;
  options: Array<{ text: string; value: unknown }>;
  optional?: boolean;
  className?: string;
  wrapperClassName?: string;
  required?: boolean;
}

export const DropdownStateWrapper = <T extends FieldValues>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  disabled,
  hint,
  options,
  optional,
  label,
  className,
  wrapperClassName,
  required,
  ...rest
}: DropdownStateWrapperProps<T>) => {
  const {
    field,
    fieldState: { error, isTouched },
  } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
    disabled,
  });

  const { onChange, value, ...restField } = field;
  const id = field.name;
  const showValidationMessage = Boolean(isTouched && error?.message);
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';

  return (
    <FieldWrapper className={`self-start ${wrapperClassName || ''}`}>
      {label && (
        <Label
          editorId={id}
          editorValid={!showValidationMessage}
          editorDisabled={disabled}
          optional={optional}
          className="k-form-label"
        >
          {label} {required ? <span className="text-sm">*</span> : ''}
        </Label>
      )}
      <div className={`k-form-field-wrap w-96 ${className || ''}`}>
        <DropDownList
          textField="text"
          dataItemKey="value"
          data={options}
          disabled={disabled}
          onChange={(e) => {
            onChange(e.target.value.value);
          }}
          value={options.find(
            (option) => JSON.stringify(option.value) === JSON.stringify(value),
          )}
          required={required}
          {...rest}
          {...restField}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{error?.message}</Error>}
      </div>
    </FieldWrapper>
  );
};
