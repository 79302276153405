import { useForm } from 'react-hook-form';
import { InputStateWrapper as HookedInput } from '../form-components/InputStateWrapper.tsx';
import { Site } from '../../gql/graphql.ts';
import { Button } from '@progress/kendo-react-buttons';
import { useEffect } from 'react';
import { PageTitle } from '../PageTitle.tsx';
import { Fieldset } from '../form-components/Fieldset.tsx';
import { CheckboxStateWrapper } from '../form-components/CheckboxStateWrapper.tsx';
import { EditorStateWrapper } from '../form-components/EditorStateWrapper.tsx';
import { HeroIcon } from '../../shared/HeroIcon.tsx';

interface CompanySiteEditorProps {
  value?: Site;
  onSubmit: (s: Site) => void;
}

export const CompanySiteForm: React.FC<CompanySiteEditorProps> = ({
  value,
  onSubmit,
}) => {
  const { control, formState, handleSubmit, reset } = useForm<Site>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: value,
  });

  const onFormSubmit = (values: Site) => {
    onSubmit(values);
  };

  useEffect(() => {
    reset({ ...value });
  }, [JSON.stringify(value)]);

  const { isValid, isDirty } = formState;

  return (
    <>
      <PageTitle title="Seiten" />

      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="k-form k-form-md relative flex w-full flex-col rounded-md bg-white shadow lg:w-full"
      >
        <Fieldset
          legend="Seiten"
          description="Erstellen Sie statische Seiten, welche über das Menü im Footer oder in der Seitenleiste erreicht werden können."
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <HookedInput
              name={'title'}
              label={'Titel'}
              placeholder="Impressum"
              hint={
                'Dieser Titel taucht im Browserfenster und in der Überschrift der Seite auf.'
              }
              rules={{ required: 'Dieses Feld muss ausgefüllt werden!' }}
              control={control}
            />
            <HookedInput
              name={'slug'}
              label={'Slug (Titel in der URL)'}
              placeholder="impressum"
              hint={
                'Dieser Name ist der letzte Teil der URL, unter der diese Seite erreichbar ist. Taucht in der Adresszeile des Browsers aus.'
              }
              rules={{ required: 'Dieses Feld muss ausgefüllt werden!' }}
              control={control}
            />
            <CheckboxStateWrapper
              name={'draft'}
              control={control}
              label={'Entwurf'}
            />
            <CheckboxStateWrapper
              name={'isFooterSite'}
              control={control}
              label={'Im Footer-Menü anzeigen'}
            />
            <HookedInput
              name={'externalUrl'}
              label={'Externe URL'}
              placeholder="https://www.meinefirma.de/"
              hint={
                'Wenn dieses Feld ausgefüllt ist, hat das folgende Inhalts-Feld keine Funktion.'
              }
              control={control}
            />
            <EditorStateWrapper
              name={'body'}
              control={control}
              label={'Inhalt'}
            />
          </div>
        </Fieldset>

        <div className="k-form-buttons !mt-0 justify-end rounded-md bg-gray-50 !px-8 !py-3 !text-right">
          <Button
            type={'submit'}
            themeColor={'primary'}
            className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
            disabled={!isValid || !isDirty}
            title="Speichern"
          >
            <HeroIcon name="CheckCircle" className="block h-5 w-5" />
            <div>Speichern</div>
          </Button>
        </div>
      </form>
    </>
  );
};
