import { useMutation, useQuery } from 'urql';
import { graphql } from '../../gql';
import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Maybe, SignUpCustomConditionalField } from '../../gql/graphql.ts';
import { Button } from '@progress/kendo-react-buttons';
import { PageTitle } from '../PageTitle.tsx';
import { Fieldset } from '../form-components/Fieldset.tsx';
import { HeroIcon } from '../../shared/HeroIcon.tsx';
import { InputStateWrapper } from '../form-components/InputStateWrapper.tsx';

interface SignUpFormPageProps {
  companyId: string;
}

export const CompanySignUpCustomConditionalFieldsQuery = graphql(/* GraphQL */ `
  query companySignUpCustomConditionalFields($companyId: String!) {
    companySignUpCustomConditionalFields(companyId: $companyId) {
      name
      displayName
      child {
        name
        displayName
        child {
          name
          displayName
          child {
            name
            displayName
          }
        }
      }
    }
  }
`);

export const UpdateSignUpFormSelectFieldsMutation = graphql(/* GraphQL */ `
  mutation updateSignUpFormSelectFields(
    $signUpCustomConditionalFields: SignUpCustomConditionalFieldInput
    $companyId: String!
  ) {
    updateSignUpCustomConditionalFields(
      companyId: $companyId
      signUpCustomConditionalFields: $signUpCustomConditionalFields
    )
  }
`);

interface Form {
  fields: {
    name: string;
    displayName: string;
  }[];
}

export const SignUpFormSelectPage: React.FC<SignUpFormPageProps> = ({
  companyId,
}) => {
  // TODO: Error handling
  const [{ data, fetching }, reloadFields] = useQuery({
    query: CompanySignUpCustomConditionalFieldsQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });

  const [, saveFields] = useMutation(UpdateSignUpFormSelectFieldsMutation);

  const { control, formState, handleSubmit, reset } = useForm<Form>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (!fetching && data?.companySignUpCustomConditionalFields) {
      reset({
        fields: linkedListToArray(
          data.companySignUpCustomConditionalFields,
        ).map((f) => ({
          name: f.name,
          displayName: f.displayName,
        })),
      });
    }
  }, [fetching]);

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'fields',
  });

  const { isValid, isDirty } = formState;

  const onFormSubmit = (value: Form) => {
    saveFields({
      companyId,
      signUpCustomConditionalFields: arrayToLinkedList(value),
    }).then(() => {
      reloadFields();
    });
  };

  const linkedListToArray = (
    companySignUpFormSelect: SignUpCustomConditionalField,
  ) => {
    const result = [];
    let currentRoot: Maybe<SignUpCustomConditionalField> | undefined =
      companySignUpFormSelect;
    while (currentRoot) {
      result.push(currentRoot);
      currentRoot = currentRoot.child;
    }
    return result;
  };

  const arrayToLinkedList = (formValues: Form) => {
    if (!formValues?.fields || !formValues.fields.length) {
      return null;
    }
    const root: SignUpCustomConditionalField = {
      name: formValues.fields[0].name,
      displayName: formValues.fields[0].displayName,
      child: null,
    };
    if (formValues.fields.length > 1) {
      let currentRoot = root;
      for (const field of formValues.fields.slice(1)) {
        currentRoot.child = {
          name: field.name,
          displayName: field.displayName,
          child: null,
        };
        currentRoot = currentRoot.child;
      }
    }
    return root;
  };

  const pageTitle = 'Anmeldegruppen';

  return (
    <>
      <PageTitle title={pageTitle} />

      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="k-form k-form-md relative flex w-full flex-col rounded-md bg-white shadow lg:w-full"
      >
        <Fieldset
          legend={`Anmeldegruppen`}
          description="Anmeldegruppen sind mehrstufige Auswahlmöglichkeiten, die bei der Anmeldung zu einem Seminar abgefragt werden."
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <Button
              onClick={() => append({ name: '', displayName: '' })}
              disabled={fields.length === 4}
              // className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              className={'self-end'}
              title="Gruppe hinzufügen"
            >
              <HeroIcon name="Plus" className="h-5 w-5" />
            </Button>
            {fields.map((field, i) => (
              <div
                className={'flex flex-col gap-4 rounded-md border p-4'}
                key={field.id}
              >
                <div className={'flex justify-between'}>
                  <h4>{i + 1}. Feld</h4>
                  <Button
                    onClick={() => remove(i)}
                    className={'self-end'}
                    title="Feld entfernen"
                    // className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  >
                    <HeroIcon
                      aria-label="Feld entfernen"
                      name="Trash"
                      className="h-5 w-5"
                    />
                  </Button>
                </div>
                <InputStateWrapper
                  name={`fields.${i}.name`}
                  control={control}
                  label={'Name'}
                  rules={{ required: true }}
                />
                <InputStateWrapper
                  name={`fields.${i}.displayName`}
                  control={control}
                  label={'Anzeigename'}
                  rules={{ required: true }}
                />
              </div>
            ))}
            {!fields.length && (
              <div>Aktuell sind keine Anmeldegruppen vorhanden!</div>
            )}
          </div>
        </Fieldset>
        <div className="k-form-buttons !mt-0 justify-end rounded-md bg-gray-50 !px-8 !py-3 !text-right">
          <Button
            type={'submit'}
            themeColor={'primary'}
            className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
            disabled={!isValid || !isDirty}
            title="Speichern"
          >
            <HeroIcon name="CheckCircle" className="block h-5 w-5" />
            <div>Speichern</div>
          </Button>
        </div>
      </form>
    </>
  );
};
