import { useMutation, useQuery } from 'urql';
import { graphql } from '../../gql';
import { MailTemplate } from '../../gql/graphql.ts';
import { MailTemplateEditor } from './MailTemplateEditor.tsx';

interface MailSettingsPageProps {
  companyId: string;
}

const MailSettingsParticipantWaitlistTemplateQuery = graphql(/* GraphQL */ `
  query mailSettingsParticipantWaitlistTemplateQuery($companyId: String!) {
    company(companyId: $companyId) {
      mailSettings {
        templateParticipantMailWaitlist {
          subject
          template
        }
      }
    }
  }
`);

const UpdateParticipantWaitlistTemplateQuery = graphql(/* GraphQL */ `
  mutation updateParticipantWaitlistTemplateQuery(
    $companyId: String!
    $company: UpdateCompanyDto!
  ) {
    updateCompany(companyId: $companyId, company: $company) {
      mailSettings {
        templateParticipantMailWaitlist {
          subject
          template
        }
      }
    }
  }
`);

export const SignUpConfirmationWaitlistTemplateEditor: React.FC<
  MailSettingsPageProps
> = ({ companyId }) => {
  // TODO: Error handling
  const [{ data }, reExecuteQuery] = useQuery({
    query: MailSettingsParticipantWaitlistTemplateQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });

  const [, updateCompany] = useMutation(UpdateParticipantWaitlistTemplateQuery);

  const onFormSubmit = (values: MailTemplate) => {
    updateCompany({
      companyId,
      company: { mailSettings: { templateParticipantMailWaitlist: values } },
    }).then(() => {
      // TODO: Check for errors and indicate success/failure to UI
      reExecuteQuery();
    });
  };

  return (
    <>
      {data?.company.mailSettings.templateParticipantMailWaitlist && (
        <MailTemplateEditor
          companyId={companyId}
          template={data.company.mailSettings.templateParticipantMailWaitlist}
          onSubmit={onFormSubmit}
        />
      )}
    </>
  );
};
