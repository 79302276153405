import { useMutation } from 'urql';
import { Site } from '../../gql/graphql.ts';
import React from 'react';
import { graphql } from '../../gql/index.ts';
import { useLocation } from 'wouter';
import { CompanySiteForm } from './CompanySiteForm.tsx';

interface CompanySiteNewProps {
  companyId: string;
}

const AddSiteMutation = graphql(/* GraphQL */ `
  mutation addCompanySite($companyId: String!, $site: SiteInput!) {
    addCompanySite(companyId: $companyId, site: $site) {
      slug
      title
      body
      draft
      externalUrl
      isFooterSite
    }
  }
`);

export const CompanySiteNewPage: React.FC<CompanySiteNewProps> = ({
  companyId,
}) => {
  const [, addCompany] = useMutation(AddSiteMutation);
  const [, setLocation] = useLocation();

  const onFormSubmit = (value: Site) => {
    addCompany({ companyId, site: value }).then(() => {
      setLocation('/sites');
    });
  };

  return (
    <CompanySiteForm
      value={{ slug: '', title: '', draft: true, isFooterSite: false }}
      onSubmit={(s) => onFormSubmit(s)}
    />
  );
};
