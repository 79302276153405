import { HeroIcon } from './HeroIcon';

type AlertTypes =
  | 'error'
  | 'warning'
  | 'info'
  | 'notice'
  | 'success'
  | 'default';

interface AlertProps {
  icon?: string;
  type?: AlertTypes;
  className?: string;
  children: React.ReactNode;
}

const classes: Record<AlertTypes, string> = {
  success: 'bg-green-100 border-green-400 text-green-700',
  error: 'bg-red-100 border-red-400 text-red-700',
  info: 'bg-sky-100 border-sky-400 text-sky-700',
  warning: 'bg-yellow-100 border-yellow-400 text-yellow-700',
  notice: 'bg-slate-100 border-slate-400 text-slate-700',
  default: '',
};

export const Alert: React.FC<AlertProps> = ({
  icon,
  className,
  type,
  children,
}) => {
  return (
    <div
      className={`col-span-2 flex gap-4 rounded-md border p-4 ${classes[type || 'default']} ${className || ''}`}
    >
      {icon && <HeroIcon className="h-6 w-6 shrink-0" name={icon} />}
      {children}
    </div>
  );
};
