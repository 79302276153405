import { FieldWrapper } from '@progress/kendo-react-form';
import {
  NumericTextBox,
  NumericTextBoxProps,
} from '@progress/kendo-react-inputs';

import { Error, Hint, Label } from '@progress/kendo-react-labels';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

interface NumericInputStateWrapperProps<T extends FieldValues>
  extends UseControllerProps<T>,
    Omit<NumericTextBoxProps, 'defaultValue' | 'name'> {
  validationMessage?: string;
  touched?: boolean;
  label?: string;
  disabled?: boolean;
  hint?: string;
  type?: string;
}

export const NumericInputStateWrapper = <T extends FieldValues>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  disabled,
  hint,
  label,
  ...rest
}: NumericInputStateWrapperProps<T>) => {
  const {
    field,
    fieldState: { error, isTouched },
  } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
    disabled,
  });

  const id = field.name;
  const showValidationMessage = Boolean(isTouched && error);
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return (
    <FieldWrapper>
      {label && (
        <Label
          editorId={id}
          editorValid={!showValidationMessage}
          editorDisabled={disabled}
          optional={!rules?.required}
          className="k-form-label"
        >
          {label}
        </Label>
      )}
      <div className={'k-form-field-wrap'}>
        <NumericTextBox
          {...rest}
          {...field}
          valid={!showValidationMessage}
          id={id}
          disabled={disabled}
          ariaDescribedBy={`${hintId} ${errorId}`}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{error?.message}</Error>}
      </div>
    </FieldWrapper>
  );
};
