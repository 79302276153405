import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { graphql } from '../gql';
import { useQuery } from 'urql';
import { SeminarCard } from './SeminarCard.tsx';
import { usePublicCompany } from './hooks/usePublicCompany.ts';
import {
  ModulePrivileges,
  PublicSeminarPageFieldType,
} from '../gql/graphql.ts';
import { PublicDetailsSidebar } from './PublicDetailsSidebar.tsx';
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout';
import {
  Grid,
  GridColumn,
  GridFilterChangeEvent,
  GridNoRecords,
  GridToolbar,
} from '@progress/kendo-react-grid';
import {
  CompositeFilterDescriptor,
  filterBy,
} from '@progress/kendo-data-query';
import { cloneAndRemoveTypename } from './util.ts';
import { useUserInfo } from './hooks/useUserInfo.ts';
import { ConditionalWrapper } from '../shared/ConditionalWrapper.tsx';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import { PublicSeminarFilesTab } from './PublicSeminarFilesTab.tsx';

const PublicSeminarQuery = graphql(/* GraphQL */ `
  query getPublicSeminar($seminarId: String!, $companyId: String!) {
    publicSeminar(seminarId: $seminarId, companyId: $companyId) {
      id
      title
      subtitle
      seminarNumber
      startDate
      endDate
      dateText
      description
      locationText
      registrationDate
      price
      priceText
      price2
      priceText2
      price3
      priceText3
      price4
      priceText4
      isBookedOut
      isAlmostBookedOut
      canceled
      files {
        blobId
        accessPermissionLevel
        specificSignUpId
        pathToFile
        uploadedAt
        name
        size
      }
      body {
        type
        html
      }
      lecturers {
        name
        id
        jobTitle
        vita
        pathToProfileImage
      }
      categories {
        name
      }
      signedUpAt
      currentUserIsLecturer
    }
  }
`);

const PublicSignUpsForSeminarQuery = graphql(/* GraphQL */ `
  query getPublicSignUpsSeminar($seminarId: String!, $companyId: String!) {
    signUpsForSeminar(seminarId: $seminarId, companyId: $companyId) {
      firstName
      lastName
      email
      id
      linkedToAccount
    }
  }
`);

interface PublicSeminarProps {
  seminarId: string;
  companyId: string;
}

export const PublicSeminar: React.FC<PublicSeminarProps> = ({
  seminarId,
  companyId,
}) => {
  const [{ data }, refresh] = useQuery({
    query: PublicSeminarQuery,
    variables: {
      companyId,
      seminarId,
    },
    pause:
      companyId === undefined ||
      companyId === null ||
      seminarId === undefined ||
      seminarId === null,
  });

  const { userInfo } = useUserInfo();

  const currentUserCompany =
    userInfo?.data?.companiesForSessionUser?.companiesForUser.find(
      (company) => company.id === companyId,
    );

  const userHasParticipantListPrivilege = Boolean(
    currentUserCompany?.privileges.includes(
      ModulePrivileges.UseParticipantList,
    ),
  );

  const [{ data: dataSignUps }] = useQuery({
    query: PublicSignUpsForSeminarQuery,
    variables: {
      companyId,
      seminarId,
    },
    pause:
      !data?.publicSeminar.currentUserIsLecturer &&
      !userHasParticipantListPrivilege,
  });

  const { publicSeminar: seminar } = data || {};

  const [publicCompanyData] = usePublicCompany();

  const getHtmlElement = (
    type: PublicSeminarPageFieldType,
    content: string,
  ) => {
    const commonBoxClasses = 'border px-4 py-3 rounded relative my-2';
    // Would love to replace this with an actual React component <Alert>, but this is raw html injected into the DOM, so can't use React components here
    const mapping: Record<string, string> = {
      error: `<div class="bg-red-100 border-red-400 text-red-700 prose-headings:text-red-700 ${commonBoxClasses}">${content}</div>`,
      info: `<div class="bg-sky-100 border-sky-400 text-sky-700 prose-headings:text-sky-700 ${commonBoxClasses}">${content}</div>`,
      warning: `<div class="bg-yellow-100 border-yellow-400 text-yellow-700 prose-headings:text-yellow-700 ${commonBoxClasses}">${content}</div>`,
      notice: `<div class="bg-slate-100 border-slate-400 text-slate-700 prose-headings:text-slate-700  ${commonBoxClasses}">${content}</div>`,
      heading: `<div class="font-bold text-3xl my-2">${content}</div>`,
      normal: `<div>${content}</div>`,
    };
    return mapping[type?.toLowerCase() || 'normal'];
  };

  const bodyHtml = seminar?.body
    ?.map(
      (item) => item.type && item.html && getHtmlElement(item.type, item.html),
    )
    .filter(Boolean)
    .join('');

  const hasWaitlist =
    publicCompanyData?.functionalSettings?.waitlistActive &&
    seminar?.isBookedOut;

  const [selected, setSelected] = React.useState<number>(0);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>({
    logic: 'and',
    filters: [],
  });

  const [page, setPage] = useState({
    skip: 0,
    take: 10,
  });

  const filteredParticipantsData = filterBy(
    dataSignUps?.signUpsForSeminar
      ? cloneAndRemoveTypename(dataSignUps?.signUpsForSeminar)
      : [],
    filter,
  );

  const isBeforeRegistrationDate = seminar?.registrationDate
    ? new Date(seminar?.registrationDate) > new Date()
    : true;

  const isBeforeEndOfSeminar = new Date(seminar?.endDate) > new Date();

  const userIsLecturerForThisSeminar = Boolean(
    data?.publicSeminar.currentUserIsLecturer,
  );
  const userIsSignedUp = Boolean(seminar?.signedUpAt);
  const seminarHasFiles = Boolean(data?.publicSeminar.files.length);

  const userHasFilePrivileges = Boolean(
    currentUserCompany?.privileges?.includes(
      ModulePrivileges.CanAccessAllSpecificParticipantFiles,
    ) ||
      currentUserCompany?.privileges?.includes(
        ModulePrivileges.CanAccessAllParticipantsFiles,
      ) ||
      currentUserCompany?.privileges?.includes(
        ModulePrivileges.CanAccessAllLecturerFiles,
      ),
  );

  const userHasFileOrSeminarListPrivilege = Boolean(
    userHasFilePrivileges || userHasParticipantListPrivilege,
  );

  const userHasTabLayout =
    userIsLecturerForThisSeminar ||
    (userIsSignedUp && seminarHasFiles) ||
    userHasFileOrSeminarListPrivilege;

  return (
    <>
      <Helmet>
        <title>
          {seminar?.title || 'Seminar'} - {import.meta.env.VITE_APP_TITLE}
        </title>
      </Helmet>
      {/* <div className="col-span-12"><h2>Seminardetails</h2></div> */}
      <div className="col-span-1 flex flex-col gap-4 md:col-span-9">
        <ConditionalWrapper
          condition={userHasTabLayout}
          wrapper={(children) => {
            const tabStripTabClasses = '!p-8';

            return (
              <TabStrip
                selected={selected}
                onSelect={(e: TabStripSelectEventArguments) => {
                  setSelected(e.selected);
                }}
                animation={false}
                className="[&_.k-active_.k-link]:!text-gray-500 [&_.k-link]:!text-bts-theme-primary focus:[&_.k-tabstrip-item]:!shadow-none"
              >
                <TabStripTab
                  contentClassName={tabStripTabClasses}
                  title="Beschreibung"
                >
                  {children}
                </TabStripTab>
                {(userIsLecturerForThisSeminar ||
                  userHasParticipantListPrivilege) && (
                  <TabStripTab
                    contentClassName={tabStripTabClasses + ' md:!p-0'}
                    title={`Teilnehmer ${filteredParticipantsData.length > 0 ? `(${filteredParticipantsData.length})` : ''}`}
                  >
                    <Grid
                      className="h-auto !border-0 [&_.k-grid-norecords-template]:border-0"
                      data={filteredParticipantsData.slice(
                        page.skip,
                        page.take + page.skip,
                      )}
                      onFilterChange={(e: GridFilterChangeEvent) =>
                        setFilter(e.filter)
                      }
                      filterable={true}
                      scrollable={'none'}
                      filter={filter}
                      take={page?.take}
                      skip={page?.skip}
                      total={filteredParticipantsData.length}
                      onPageChange={(event) => {
                        setPage(event.page);
                      }}
                      pageable
                    >
                      {Boolean(data?.publicSeminar.currentUserIsLecturer) &&
                        filteredParticipantsData.length > 0 && (
                          <GridToolbar className="!flex !justify-end">
                            <a
                              href={`mailto:${userInfo?.data?.companiesForSessionUser.user.email}?bcc=${filteredParticipantsData.map((d) => d.email).join(',')}`}
                              className="k-button k-button-sm k-rounded-md k-button-outline k-button-outline-secondary"
                              title="Feld bearbeiten"
                            >
                              <HeroIcon
                                name="Envelope"
                                className="block h-5 w-5"
                              />
                              E-Mail an alle Teilnehmer
                            </a>
                          </GridToolbar>
                        )}
                      <GridNoRecords>Keine Teilnehmer gefunden</GridNoRecords>
                      <GridColumn field="email" title="E-Mail" />
                      <GridColumn field="firstName" title="Vorname" />
                      <GridColumn field="lastName" title="Nachname" />
                    </Grid>
                  </TabStripTab>
                )}
                {(userIsLecturerForThisSeminar ||
                  (userIsSignedUp && seminarHasFiles) ||
                  userHasFilePrivileges) && (
                  <TabStripTab
                    contentClassName={tabStripTabClasses + ' md:!p-0'}
                    title={`Dateien ${data?.publicSeminar?.files?.length ? `(${data?.publicSeminar.files?.length})` : ''}`}
                  >
                    <PublicSeminarFilesTab
                      seminarId={seminarId}
                      companyId={companyId}
                      participants={filteredParticipantsData}
                      files={
                        data?.publicSeminar.files &&
                        cloneAndRemoveTypename(data?.publicSeminar.files)
                      }
                      showUploadSection={
                        userIsLecturerForThisSeminar || userHasFilePrivileges
                      }
                      refreshData={refresh}
                      showSpecificParticipantFilesOption={currentUserCompany?.privileges?.includes(
                        ModulePrivileges.CanAccessAllSpecificParticipantFiles,
                      )}
                      showAllParticipantFilesOption={
                        userIsLecturerForThisSeminar ||
                        currentUserCompany?.privileges?.includes(
                          ModulePrivileges.CanAccessAllParticipantsFiles,
                        )
                      }
                      showAllLecturerFilesOption={
                        userIsLecturerForThisSeminar ||
                        currentUserCompany?.privileges?.includes(
                          ModulePrivileges.CanAccessAllLecturerFiles,
                        )
                      }
                    />
                  </TabStripTab>
                )}
              </TabStrip>
            );
          }}
        >
          <SeminarCard
            singleView
            id={seminar?.seminarNumber}
            title={seminar?.title}
            description={bodyHtml}
            subtitle={seminar?.subtitle}
            category={
              seminar?.categories?.find((c) => Boolean(c.name.trim()))?.name
            }
            isSignedUp={Boolean(seminar?.signedUpAt)}
            signUpLink={
              publicCompanyData?.functionalSettings.registrationActive &&
              isBeforeEndOfSeminar &&
              isBeforeRegistrationDate &&
              !seminar?.canceled &&
              (!seminar?.isBookedOut || hasWaitlist)
                ? '/sign-up'
                : undefined
            }
            signUpLinkCaption={
              hasWaitlist
                ? seminar?.signedUpAt
                  ? 'Weitere Anmeldung auf der Warteliste'
                  : 'Auf die Warteliste'
                : seminar?.signedUpAt
                  ? 'Weitere Person anmelden'
                  : 'Anmelden'
            }
            signUpLinkLogo={hasWaitlist ? 'QueueList' : 'AcademicCap'}
            tabbed={userHasTabLayout}
          />
        </ConditionalWrapper>
      </div>
      {seminar && (
        <PublicDetailsSidebar
          seminar={seminar}
          showSignalLight={
            publicCompanyData?.visualSettings.showSignalLight &&
            !seminar.canceled
          }
        />
      )}
    </>
  );
};
