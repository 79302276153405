import { useMutation, useQuery } from 'urql';
import React, { useEffect } from 'react';
import { graphql } from '../gql/index.ts';
import { Flags } from '../gql/graphql.ts';
import { useForm } from 'react-hook-form';
import { InputStateWrapper as HookedInput } from './form-components/InputStateWrapper.tsx';
import { Button } from '@progress/kendo-react-buttons';
import { PageTitle } from './PageTitle.tsx';
import { Fieldset } from './form-components/Fieldset.tsx';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import { cloneAndRemoveTypename } from '../public/util.ts';

interface CompanyFlagsPageProps {
  companyId: string;
}

const CompanyFlagsQuery = graphql(/* GraphQL */ `
  query companyFlags($companyId: String!) {
    companyFlags(companyId: $companyId) {
      flag1
      flag2
      flag3
      flag4
      flag5
      flag6
      flag7
      flag8
      flag9
      flag10
      flag11
      flag12
      flag13
      flag14
      flag15
      flag16
      flag17
      flag18
      flag19
      flag20
    }
  }
`);

const UpdateFlagsMutation = graphql(/* GraphQL */ `
  mutation updateCompanyFlags($companyId: String!, $flags: FlagsInput!) {
    updateCompanyFlags(companyId: $companyId, flags: $flags) {
      flag1
      flag2
      flag3
      flag4
      flag5
      flag6
      flag7
      flag8
      flag9
      flag10
      flag11
      flag12
      flag13
      flag14
      flag15
      flag16
      flag17
      flag18
      flag19
      flag20
    }
  }
`);

export const CompanyFlagsPage: React.FC<CompanyFlagsPageProps> = ({
  companyId,
}) => {
  // TODO: Error handling
  const [{ data, fetching }] = useQuery({
    query: CompanyFlagsQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });

  useEffect(() => {
    if (!fetching) {
      const withoutTypename =
        data?.companyFlags && cloneAndRemoveTypename(data?.companyFlags);
      reset(withoutTypename);
    }
  }, [fetching]);

  const [, updateCompany] = useMutation(UpdateFlagsMutation);

  const onFormSubmit = (value: Flags) => {
    updateCompany({ companyId, flags: value }).then(() => {});
  };

  const { control, formState, handleSubmit, reset } = useForm<Flags>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { isValid, isDirty } = formState;

  return (
    <>
      <PageTitle title="Flags" />
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="k-form k-form-md relative flex w-full flex-col rounded-md bg-white shadow lg:w-full"
      >
        <Fieldset
          legend="Flags"
          description="Flags sind flexible Attribute, die einem Seminar zugeordnet werden können. Im Gegensatz zu Kategorien kann ein Seminar über mehrere Flags verfügen."
        >
          <div className="grid grid-cols-2 gap-x-8 gap-y-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <HookedInput name={'flag1'} label={'Flag 1'} control={control} />
            <HookedInput name={'flag2'} label={'Flag 2'} control={control} />
            <HookedInput name={'flag3'} label={'Flag 3'} control={control} />
            <HookedInput name={'flag4'} label={'Flag 4'} control={control} />
            <HookedInput name={'flag5'} label={'Flag 5'} control={control} />
            <HookedInput name={'flag6'} label={'Flag 6'} control={control} />
            <HookedInput name={'flag7'} label={'Flag 7'} control={control} />
            <HookedInput name={'flag8'} label={'Flag 8'} control={control} />
            <HookedInput name={'flag9'} label={'Flag 9'} control={control} />
            <HookedInput name={'flag10'} label={'Flag 10'} control={control} />
            <HookedInput name={'flag11'} label={'Flag 11'} control={control} />
            <HookedInput name={'flag12'} label={'Flag 12'} control={control} />
            <HookedInput name={'flag13'} label={'Flag 13'} control={control} />
            <HookedInput name={'flag14'} label={'Flag 14'} control={control} />
            <HookedInput name={'flag15'} label={'Flag 15'} control={control} />
            <HookedInput name={'flag16'} label={'Flag 16'} control={control} />
            <HookedInput name={'flag17'} label={'Flag 17'} control={control} />
            <HookedInput name={'flag18'} label={'Flag 18'} control={control} />
            <HookedInput name={'flag19'} label={'Flag 19'} control={control} />
            <HookedInput name={'flag20'} label={'Flag 20'} control={control} />
          </div>
        </Fieldset>

        <div className="k-form-buttons !mt-0 justify-end rounded-md bg-gray-50 !px-8 !py-3 !text-right">
          <Button
            type={'submit'}
            themeColor={'primary'}
            className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
            disabled={!isValid || !isDirty}
            title="Speichern"
          >
            <HeroIcon name="CheckCircle" className="block h-5 w-5" />
            <div>Speichern</div>
          </Button>
        </div>
      </form>
    </>
  );
};
